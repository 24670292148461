import React from 'react';
import C18Select00 from './C18Select00'
import {wsTrans,sensorIds,getUId,doGetPostBasic,makeOpts,getParamId2,logIt,
  xBoardShorts,allSense
} from '../utils/utils'
import {getZoneInfo, loadSiteData,} from './C18utils'
import C18SearchTabs00 from "./C18SearchTabs00";

import { cl } from '../../components/utils/utils';
// simple class component to show the alarm tabs
class C18AlarmPortal extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loaded: false,
        selectedId: "a036uzDCxohZ7ovD",
        selectedAlarmId: "",
        serverStatus: "",
        allAlarms: [],
        allAlarmEmails: [],
        recentAlarms: [],
        selectedAlarms: [],
        recentSelectedAlarms: [],
        selectedAlarmEmails: [],
        selectedMailgunEmails: [],
        selectedRecord: "",
        sortColumn: "",
        sortOrder: "",
      };

      this.host = 'https://api01.link4cloud.com'
      this.bgColor1=(this.dark)?"#202020":"#FFFFFF"
      this.bgColor2=(this.dark)?"#203030":"#FFFFFF"
      this.selColor1=(this.dark)?"#606060":"#DDDDDD"
    }


  // API CALLS
  // fetch last time server sent out emails
  refreshServerStatus = async () => {
    const url =`${this.host}/status/75cgVDOLOXprBe8fk*M9OktUH5EJXd38/alarmServerStatus/`;
    try {
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      cl(resp)
      const data = await resp.json();
      cl(data)
      let currDate = new Date(data.lastCheckAlarm * 1000).toLocaleString()
      cl(currDate)
      this.setState({serverStatus: currDate})
    } catch (error) {
      cl("Error fetching server health check", error);
    }
  }

  // Fetch method as a class method
  getAlarms = async () => {
    const url = `${this.host}/status/75cgVDOLOXprBe8fk*M9OktUH5EJXd38/activeAlarms/`;
    try {
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const data = await resp.json();
      // cl(data)
      return data
    } catch (error) {
      cl("Error fetching alarms:", error);
    }
  }

  getAlarmEmails = async () => {
    const url =`${this.host}/status/75cgVDOLOXprBe8fk*M9OktUH5EJXd38/alarmEmails/`;
    try {
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const data = await resp.json();
      // filter out notifications that weren't sent
      let emails = []
      for (let i = 0; i < data.length; i++) {
        let notification = data[i]
        for (let email in notification.emails) {
          if (notification.emails.hasOwnProperty(email)) {
            emails.push(notification)
            break
          }
        }
      }
      cl(emails)
      return emails
    } catch (error) {
      cl("Error fetching alarm emails", error);
    }
  };

  getSelectedMailgunEmails = async (userId) => {
    const url =`${this.host}/status/75cgVDOLOXprBe8fk*M9OktUH5EJXd38/mailgun/${userId}`;
    try {
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const data = await resp.json();
      let emails = []
      for (let i = 0; i < data.items.length; i++) {
        data.items[i].title = data.items[i].message.headers.subject
        emails.push(data.items[i])
      }
      return emails
    } catch (error) {
      cl("Error fetching mailgun emails", error);
    }
  };

  getAlarmConfig = async (siteId, siteZoneIndex) => {
    const url = `https://api02.link4cloud.com/status/75cgVDOLOXprBe8fk*M9OktUH5EJXd38/alarmConfig/${siteId}/${siteZoneIndex}`;
    try {
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const data = await resp.json();
      cl(data)
      return data
    } catch (error) {
      cl("Error fetching alarm config", error);
    }
  };
  
  // LOAD DATA
  componentDidMount = () =>{
    this.loadInfo()
  } 

  loadInfo= async () =>{
    this.refreshServerStatus()
    let allAlarms = await this.getAlarms()
    let allAlarmEmails = await this.getAlarmEmails()
    let yesterdayTs = (Date.now() - (24 * 60 * 60 * 1000))/1000
    let recentAlarms = allAlarms.filter((a) => {
      // cl([a?.alarmTime, yesterdayTs])
      return a?.alarmTime > yesterdayTs
    })
    // use same function to get selected accounts
    // this.fetchTotalAlarmsSelected(this.props.selValue?.accounts);
    // this.fetchAlarms24HoursSelected(this.props.selValue?.accounts);
    // this.fetchAlarmsEmails24HoursSelected(this.props.selValue?.accounts);

    this.checkServerStatus=setInterval(this.refreshServerStatus, 1000 * 60)
    // cl(allAlarms)
    // cl(allAlarmEmails)
    this.setState({allAlarms: allAlarms, allAlarmEmails: allAlarmEmails, recentAlarms: recentAlarms, loaded: true})
  }

  componentWillUnmount=()=>{
    cl("host unmount")
    clearInterval(this.checkServerStatus)
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.selectedId !== this.props.selectedId) {
      // refetch info
      const { allAlarms, allAlarmEmails } = this.state;
      cl(this.props)
      let yesterdayTs = (Date.now() - (24 * 60 * 60 * 1000))/1000
      // filter alarms and emails from state here
      // use type and id to determine what should be shown in selected
      let selectedAlarms = allAlarms.filter((a) => {
        switch (this.props.selectedType) {
          case "account":
            return a.accountId == this.props.selectedId
          case "site":
            return a.siteId == this.props.selectedId
          case "zone":
            return a.zoneId == this.props.selectedId
          default:
            break
        }
      })

      let selectedAlarmEmails = allAlarmEmails.filter((a) => {
        switch (this.props.selectedType) {
          case "account":
            return a.accountId == this.props.selectedId
          case "site":
            return a.siteId == this.props.selectedId
          case "zone":
            return a.zoneId == this.props.selectedId
          case "user":
            return a.emails[this.props.selectedId] != null
          default:
            break
        }
      })
      let selectedMailgunEmails = null
      if (this.props.selectedType == "user") {
        selectedMailgunEmails = await this.getSelectedMailgunEmails(this.props.selectedId)
      }
      // do more filtering if alarm or user is selected  
      let recentSelectedAlarms = selectedAlarms.filter((a) => {
        // cl([a?.alarmTime, yesterdayTs])
        return a?.alarmTime > yesterdayTs
      })
      this.setState({selectedAlarms: selectedAlarms,
        selectedAlarmEmails: selectedAlarmEmails,
        recentSelectedAlarms: recentSelectedAlarms,
        selectedRecord: null, selectedEmail: null,
        selectedMailgunEmails: selectedMailgunEmails,
      })
    }
  }

  // RENDER METHODS

  showStatus = (tabs) => {
    const { allAlarms, recentAlarms, selectedAlarms, recentSelectedAlarms, allAlarmEmails, selectedAlarmEmails, serverStatus } = this.state;
    let selectedName
    if (tabs == "selected") {
      selectedName = this.getSelectedName()
      if (this.props.selectedType == "user") {
        let selUser=this.props.users.filter(u=>{return u._id==this.props.selectedId})[0]
        //         cl(selUser)
        if(!selUser){
          selUser=this.props.users.filter(u=>{return u.userId==this.props.selectedId})[0]// needed for selections from system check
        }
        selectedName += ` (${selUser.email})`    
      } else if (this.props.selectedType == "zone") {
        let selZone=this.props.zones.filter(z=>{return z.zoneId==this.props.selectedId})[0]
        if (!selZone.connected) {
          selectedName += " (Offline)"  
        }
      }
    }
    return (
        <>
        {tabs == "selected" && <h2>Selected: <i>{selectedName}</i></h2>}
        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%', marginTop: '20px' }}>
          {/* Tab 1 */}
          {(tabs == "general" || tabs == "selected" && this.props.selectedType != "user") &&
            <div
            id="activeAlarmsTab"
            style={{ border: '1px solid #ccc', borderRadius: '8px', width: '100%', height: '150px', textAlign: 'center', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', fontSize: '18px' }}
          >
            <h3 style={{ margin: 0, fontSize: '20px' }}>Total Active Alarms</h3>
            <p style={{ fontSize: '20px', marginTop: '20px' }}>{tabs == "selected" ? selectedAlarms.length : allAlarms.length}</p>
          </div>
          }
          {/* Tab 2 */}
          {(tabs == "general" || tabs == "selected" && this.props.selectedType != "user") &&
          <div
            id="pastAlarmsTab"
            style={{ border: '1px solid #ccc', borderRadius: '8px', width: '100%', height: '150px', textAlign: 'center', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', fontSize: '18px' }}
          >
            <h3 style={{ margin: 0, fontSize: '20px' }}>New Alarms in Past 24 Hours</h3>
            <p style={{ fontSize: '20px', marginTop: '20px' }}>{tabs == "selected" ? recentSelectedAlarms.length : recentAlarms.length}</p>
          </div>
          }
          {/* Tab 3 */}
          <div
            id="pastAlarmsEmailsTab"
            style={{ border: '1px solid #ccc', borderRadius: '8px', width: '100%', height: '150px', textAlign: 'center', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', fontSize: '18px' }}
          >
            <h3 style={{ margin: 0, fontSize: '20px' }}>Alarm Notifications in Past 24 Hours</h3>
            <p style={{ fontSize: '20px', marginTop: '20px' }}>{tabs == "selected" ? selectedAlarmEmails.length : allAlarmEmails.length}</p>
          </div>
    

          {/* Tab 5 */}
          { (tabs === "general") && (<div
            id="serverHealthCheckTab"
            style={{ border: '1px solid #ccc', borderRadius: '8px', width: '100%', height: '150px', textAlign: 'center', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', fontSize: '18px' }}
          >
            <h3 style={{ margin: 0, fontSize: '20px' }}>Last Sent</h3>
            <p style={{ fontSize: '20px', marginTop: '20px' }}>{serverStatus}</p>
          </div>)}
        </div>
        </>
      );
  }

  showSearchTabs=()=>{
    return <C18SearchTabs00 {...this.props} />;
  }


  showSearchBox=(type)=>{
//     cl(st.searchRes[type])
    return(
      <div style={{width:"100%",height:200,backgroundColor:this.bgColor2,marginRight:30,
        padding:10,overflowY:"auto",
      }}>
      <table><tbody>
      {this.props.searchRes[type].map((r,i)=>{
        let txt=(r.t)?r.t:"No Name"
//         cl(r)
        let bg=(r.v==this.props.searchSel)?this.selColor1:null
        return(
          <tr key={i} style={{backgroundColor:bg,cursor:"pointer"}}
            onClick={e=>this.props.onChange("sel",{type:type,searchSel:r.v,id:r.id})}
          ><td>{txt}</td></tr>
        )})}
      </tbody></table>
      </div>
    )
  }

  showSelectType=(relTypes,type)=>{
    let parms={
      account:{t:"Accounts",v:"accountSel"},
      user:{t:"Users",v:"userSel"},
      site:{t:"Sites",v:"siteSel"},
      gateway:{t:"Gateways",v:"gatewaySel"},
      zone:{t:"Zones",v:"zoneSel"},
    }[type]
    return(
      <div className="custom-select">
        <div className="clearfloat"></div><br/>
        <label htmlFor="">Related {parms.t}</label>
        <C18Select00 id=""
          parms={{list:true,height:200}}
          value={this.props[parms.v]}
          onChange={e=>{
            let vals={}
            vals[parms.v]=e.currentTarget.value
            this.props.onChange(parms.v,vals)}
          }
        >
          {makeOpts(relTypes)}
        </C18Select00>
      </div>
    )
  }

  showAccounts=()=>{
//     let selAccount=st.accounts.filter(a=>{return a.accountId==st.selectedId})[0]
    let height = "auto"
    let type = "account"
    return(
      <div style={{width:300,height:height,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.props.onChange("refresh",{element:"accounts"})}}
      >Accounts</h3>
      {
        ((this.props.searchRes?.account?.length)?
        this.showSearchBox("account"):
        ((this.props.relAccounts.length>0)&&this.showSelectType(this.props.relAccounts,type)))
      }
      </div>
    )
  }

  showSites=()=>{
    let type="site"
    let height = "auto"
    return(
      <div style={{width:300,height:height,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.props.onChange("refresh",{element:"sites"})}}
      >Sites</h3>
      {
      ((this.props.searchRes||{})[type]?.length)?
      this.showSearchBox(type):((this.props.relSites.length>0)&&this.showSelectType(this.props.relSites,type))
      }
      </div>
    )
  }
  
  showZones=()=>{
    let height = "auto"
    let type="zone"
    return(
      <div style={{width:300,height:height,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"zones"})}}
      >Zones</h3>
      {
      ((this.props.searchRes||{})[type]?.length)?
      this.showSearchBox(type):((this.props.relZones.length>0)&&this.showSelectType(this.props.relZones,type))
      }
      </div>
    )
  }

  showUsers=()=>{
    let type="user"
    let height = "auto"
    return(
      <div style={{width:300,height:height,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"users"})}}
      >Users</h3>
      {
      (
        ((this.props.searchRes?.account?.length)?
        this.showSearchBox(type):
        ((this.props.relUsers.length>0)&&this.showSelectType(this.props.relUsers,type)))
      )
      }
      </div>
    )
  }

  getSortedData = () => {
    const { selectedAlarms, sortColumn, sortOrder } = this.state;
    if (!sortColumn) return selectedAlarms;

    return [...selectedAlarms].sort((a, b) => {
      const aValue = a[sortColumn] || a[sortColumn];
      const bValue = b[sortColumn] || b[sortColumn];

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      }
    })
  }

  getSortedEmailData = () => {
    const { selectedAlarmEmails, emailSortColumn, emailSortOrder } = this.state;
    if (!emailSortColumn) return selectedAlarmEmails

    return [...selectedAlarmEmails].sort((a, b) => {
        const aValue = a[emailSortColumn] || "";
        const bValue = b[emailSortColumn] || "";

        if (typeof aValue === "string" && typeof bValue === "string") {
            return emailSortOrder === "asc"
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
        } else {
            return emailSortOrder === "asc" ? aValue - bValue : bValue - aValue;
        }
    });
  };

  getSortedMailgunEmailData = () => {
    const { selectedMailgunEmails, mailgunEmailSortColumn, mailgunEmailSortOrder } = this.state;
    if (!mailgunEmailSortColumn) return selectedMailgunEmails

    return [...selectedMailgunEmails].sort((a, b) => {
        const aValue = a[mailgunEmailSortColumn] || "";
        const bValue = b[mailgunEmailSortColumn] || "";

        if (typeof aValue === "string" && typeof bValue === "string") {
            return mailgunEmailSortOrder === "asc"
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
        } else {
            return mailgunEmailSortOrder === "asc" ? aValue - bValue : bValue - aValue;
        }
    });
  };

  onChangeSort = (column) => {
    const { sortColumn, sortOrder } = this.state;
    let newSortOrder = "asc";
    if (sortColumn === column) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }
    this.setState({ sortColumn: column, sortOrder: newSortOrder });
  };

  onChangeEmailSort = (column) => {
    const { emailSortColumn, emailSortOrder } = this.state;
    let newSortOrder = "asc";
    if (emailSortColumn === column) {
        newSortOrder = emailSortOrder === "asc" ? "desc" : "asc";
    }
    this.setState({ emailSortColumn: column, emailSortOrder: newSortOrder });
  };

  onChangeMailgunEmailSort = (column) => {
    const { mailgunEmailSortColumn, mailgunEmailSortOrder } = this.state;
    let newSortOrder = "asc";
    if (mailgunEmailSortColumn === column) {
        newSortOrder = mailgunEmailSortOrder === "asc" ? "desc" : "asc";
    }
    this.setState({ mailgunEmailSortColumn: column, mailgunEmailSortOrder: newSortOrder });
  };

  handleAlarmRowClick = async (row) => {
    // console.log("record", record); 
     // select record of zone in globs.zonesInfo.info where record.zoneId matches
    let zoneInfo = getZoneInfo(row.zoneId)
    let siteId = row.siteId;
    let siteZoneIndex = zoneInfo ? zoneInfo.siteZoneIndex : null
    // fetch query for the selected record
    // filter selected emails based on this alarm info
    let selectedEmails = this.state.selectedAlarmEmails.filter((e) => {
      return row.accountId == e.accountId && row.siteId == e.siteId && row.zoneId == e.zoneId && row.alarmId == e.alarmId
    })
    cl(selectedEmails)
    // if siteZoneIndex exists
    if (siteZoneIndex === null || siteZoneIndex === undefined) {
      this.setState({ selectedRecord: row, selectedAlarmEmails: selectedEmails, errorMessage: "No config data found for this alarm." })
    } else {
      const data = await this.getAlarmConfig(siteId, siteZoneIndex)
      cl(data)
      this.setState({ selectedRecord: row, selectedAlarmEmails: selectedEmails, alarmConfig: data });
    }
  }

  handleEmailRowClick = async (row) => {
    this.setState({ selectedEmail: row });
  }

  getSelectedName=()=>{
    switch (this.props.selectedType) {
      case "account":
        return this.props.relAccounts.find((e) => e.v == this.props.selectedId)?.t || this.props.selectedId
      case "site":
        return this.props.relSites.find((e) => e.v == this.props.selectedId)?.t || this.props.selectedId
      case "zone":
        return this.props.relZones.find((e) => e.v == this.props.selectedId)?.t || this.props.selectedId
      case "user":
        return this.props.relUsers.find((e) => e.v == this.props.selectedId)?.t || this.props.selectedId
      default:
        return ""
    }
  }

  // TODO show "ENABLED ALARMS FOR USER" if user is selected
  showSelectedAlarmTable=()=>{
    const { sortColumn, sortOrder, selectedRecord} = this.state;
    const alarmNamesMap = {
      5200: "Alarm Enable",
      5201: "Alarm Low",
      5202: "Alarm High",
      5203: "Alarm Deadband",
      5204: "Alarm Delay",
      5207: "Notify How Often",
      5208: "How Many/Day"
    }
    const tableColumns = [
      { key: "name", label: "Name" },
      { key: "alarmId", label: "ID" },
      { key: "siteId", label: "Site" },
      { key: "zoneId", label: "Zone" },
      { key: "alarmTime", label: "Start Time" },
    ]
    const sortedData = this.getSortedData()
    let selectedName = this.getSelectedName()
    return (
      <>
      <h2>Active Alarms for {this.props.selectedType}: <i>{selectedName}</i></h2>
      <div className="table-container" style={{ marginTop: "3%", overflowY: "auto", maxHeight: "380px" }}>
      <table id="alarmTable" className="list" style={{ borderSpacing: "0 5px",  width:"100%" }}>
     {/* <thead style={{ backgroundColor: '#f2f2f2' }}> */}
        <thead><tr><th>{" "}</th></tr></thead>
          <thead style={{ backgroundColor: '#f2f2f2' , position: "sticky", top: 0, zIndex: 1 ,}}>
          <tr>
              {tableColumns.map(({ key, label }) => (
                <th key={key} style={{ textAlign: "center", width: "15%" , padding: "10px"  }}>
                  <button
                    type="button"
                    onClick={() => this.onChangeSort(key)}
                    style={{
                      border: "none",
                      background: "none",
                      fontSize: "16px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    {label} {sortColumn === key ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          {/* {activeAlarmsData && activeAlarmsData.map((row, index) => ( */}
            {sortedData.map((row, index) => {
              let siteName = this.props.relSites.find((e) => e.v == row.siteId)?.t || row.siteId
              let zoneName = this.props.relZones.find((e) => e.v == row.zoneId)?.t || row.zoneId
              let duration = Math.round(((Date.now()/1000) - row.alarmTime) / (60 * 24))
              return (
                <tr key={index} onClick={() => this.handleAlarmRowClick(row)}  
                style={{
                  cursor: "pointer",
                  backgroundColor: selectedRecord === row ? "#e0e0e0" : "transparent",
                  transition: "background 0.2s ease-in-out",
                }}>
                <td style={{ padding: '10px' }}>{row.name}</td>
                  <td style={{ padding: '10px' }}>{row.alarmId}</td>
                  <td style={{ padding: '10px' }}>{siteName}</td>
                  <td style={{ padding: '10px' }}>{zoneName}</td>
                  <td style={{ padding: '10px' }}>{`${new Date(row.alarmTime * 1000).toLocaleString()} (${duration} min ago)` ?? ""}</td>
                </tr>
            )
            })
            }
          </tbody>
        </table>
      </div>
       <br/>
       <div><i>{sortedData.length} results found...</i></div>
        {this.state.errorMessage && (
    <p style={{ color: "red", fontWeight: "bold" }}>{this.state.errorMessage}</p>)}

    {false && this.state.selectedRecord && this.state.alarmConfig && this.state.alarmConfig.length > 0 && (
    <div style={{ marginTop: "20px", padding: "15px", border: "1px solid #ccc", borderRadius: "5px" }}>
        <h3>Alarm Specific Details</h3>
        <p><strong>Alarm Name:</strong> {this.state.selectedRecord.name || "Unknown Alarm"}</p>
        <hr />

        {this.state.alarmConfig.map((alarm, index) => {
            const configName = alarmNamesMap[alarm.i] || "Unknown Alarm"; 
            
            if (configName === "How Many/Day") {
                return (
                    <div key={index} style={{ marginBottom: "15px", borderBottom: "1px solid #ddd", paddingBottom: "10px" }}>
                        <p><strong>{configName}:</strong> {alarm.d}</p> 
                    </div>
                );
            }

            if (configName === "Notify How Often") {
              return (
                  <div key={index} style={{ marginBottom: "15px", borderBottom: "1px solid #ddd", paddingBottom: "10px" }}>
                      <p><strong>{configName}:</strong> {alarm.d}</p> 
                  </div>
              );
          }

            const status = alarm.d === 1 ? "Yes" : "No"; 
            return (
                <div key={index} style={{ marginBottom: "15px", borderBottom: "1px solid #ddd", paddingBottom: "10px" }}>
                    <p><strong>{configName}:</strong> {status}</p>
                </div>
            );
        })}
    </div>
  )}
       </>
    )
  }

  showSelectedEmailTable = () => { 
    const { emailErrorMessage, emailSortColumn, emailSortOrder } = this.state;
    const sortedData = this.getSortedEmailData(); 

    const tableColumns = [
        { key: "name", label: "Title" },
        { key: "alarmTime", label: "Alarm Start" },
        { key: "now", label: "Sent" },
        { key: "notifyCount", label: "Notify Count" },
        { key: "emails", label: "Recipients" }
    ];

    const formatDate = (timestamp) => {
        return new Date(timestamp * 1000).toLocaleString();
    };

    let selectedName = this.getSelectedName()
    // if user, show user email and only emails for this user
    let selUser = null
    if (this.props.selectedType == "user") {
      let selUser=this.props.users.filter(u=>{return u.userId==this.props.selectedId})[0]// needed for selections from system check
      selectedName += ` (${selUser?.email})`   
    // if alarm, show alarm and emails sent to this alarm
    }
    if (this.state.selectedRecord) {
      selectedName = `${this.state.selectedRecord.name || "Unknown Alarm"}`
    }

    return (
      <div>
        <h2>Recent Notifications for {this.state.selectedRecord ? "Alarm" : this.props.selectedType}: <i>{selectedName}</i></h2>
        <div className="table-container" style={{ marginTop: "3%", overflowY: "auto", maxHeight: "380px" }}>
          <table className="list" style={{ borderSpacing: "0 5px", width: "100%" }}>
            <thead style={{ backgroundColor: '#f2f2f2', position: "sticky", top: 0, zIndex: 1 }}>
              <tr>
                {tableColumns.map(({ key, label }) => (
                  <th key={key} style={{ textAlign: "center", width: "14%", padding: "10px" }}>
                    <button
                      type="button"
                      onClick={() => this.onChangeEmailSort(key)}
                      style={{ border: "none", background: "none", fontSize: "16px", fontWeight: "bold", cursor: "pointer" }}
                    >
                      {label} {emailSortColumn === key ? (emailSortOrder === "asc" ? "▲" : "▼") : ""}
                    </button>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedData.map((row, index) => {
                let recipients = []
                for (let email in row.emails) {
                  recipients.push(row.emails[email].email)
                }
                return (
                    <tr key={index} onClick={() => this.handleEmailRowClick(row)}
                    style={{
                      cursor: "pointer",
                      backgroundColor: this.state.selectedEmail === row ? "#e0e0e0" : "transparent",
                      transition: "background 0.2s ease-in-out",
                    }}> 
                        <td style={{ padding: "10px" }}>{row.title || "N/A"}</td>
                        <td style={{ padding: "10px" }}>{formatDate(row.alarmTime)}</td>
                        <td style={{ padding: "10px" }}>{formatDate(row.now)}</td>
                        <td style={{ padding: "10px" }}>{row.notifyCount}/{row.notifyPerDay}</td>
                        <td style={{ padding: "10px" }}>{recipients.toString(", ")}</td>
                    </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div><i>{sortedData.length} results found...</i></div>
        {emailErrorMessage && <p style={{ color: "red", fontWeight: "bold" }}>{emailErrorMessage}</p>}
        {/* user info */}
        {selUser && this.showSelectedUserInfo(selUser)}
        {/* alarm info */}
        {this.state.selectedRecord && this.showSelectedAlarmInfo(this.state.selectedRecord)}
        {/* email info */}
        {this.state.selectedEmail && this.showSelectedEmailInfo(this.state.selectedEmail)}
      </div>
    );
  };

  showSelectedMailgunEmailTable = () => { 
    const { mailgunEmailErrorMessage, mailgunEmailSortColumn, mailgunEmailSortOrder } = this.state;
    const sortedData = this.getSortedMailgunEmailData(); 
    const tableColumns = [
        { key: "title", label: "Title" },
        { key: "@timestamp", label: "Sent" },
        { key: "event", label: "Status" },
    ];

    let selectedName = this.getSelectedName()
    // if user, show user email and only emails for this user
    if (this.props.selectedType == "user") {
      let selUser=this.props.users.filter(u=>{return u.userId==this.props.selectedId})[0]// needed for selections from system check
      selectedName += ` (${selUser?.email})`   
    // if alarm, show alarm and emails sent to this alarm
    }

    return (
      <div>
        <h2>Recent Emails for {this.state.selectedRecord ? "Alarm" : this.props.selectedType}: <i>{selectedName}</i></h2>
        <div className="table-container" style={{ marginTop: "3%", overflowY: "auto", maxHeight: "380px" }}>
          <table className="list" style={{ borderSpacing: "0 5px", width: "100%" }}>
            <thead style={{ backgroundColor: '#f2f2f2', position: "sticky", top: 0, zIndex: 1 }}>
              <tr>
                {tableColumns.map(({ key, label }) => (
                  <th key={key} style={{ textAlign: "center", width: "14%", padding: "10px" }}>
                    <button
                      type="button"
                      onClick={() => this.onChangeMailgunEmailSort(key)}
                      style={{ border: "none", background: "none", fontSize: "16px", fontWeight: "bold", cursor: "pointer" }}
                    >
                      {label} {mailgunEmailSortColumn === key ? (mailgunEmailSortOrder === "asc" ? "▲" : "▼") : ""}
                    </button>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedData.map((row, index) => {
                return (
                    <tr key={index} 
                    style={{
                      cursor: "pointer",
                      backgroundColor: "transparent",
                      transition: "background 0.2s ease-in-out",
                    }}> 
                        <td style={{ padding: "10px" }}>{row.title || "N/A"}</td>
                        <td style={{ padding: "10px" }}>{row["@timestamp"]}</td>
                        <td style={{ padding: "10px" }}>{row.event}</td>
                    </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div><i>{sortedData.length} results found...</i></div>
        {mailgunEmailErrorMessage && <p style={{ color: "red", fontWeight: "bold" }}>{mailgunEmailErrorMessage}</p>}
      </div>
    );
  };

  showSelectedUserInfo=(info)=>{
    return (
      <div className="manage-account-wrapper">
        <div className="manage-account">
          <h2>User Info</h2>
          <label htmlFor="account-zone">Name</label>
          <span>{info.name}</span>
          <label htmlFor="account-zone">Email</label>
          <span>{info.email}</span>
          <label htmlFor="account-zone">Active</label>
          <span>{info.active}</span>
          <label htmlFor="account-zone">Email Enabled</label>
          <span>{info.noteInfo?.enableEmail}</span>
          <label htmlFor="account-zone">Push Enabled</label>
          <span>{info.noteInfo?.enablePush}</span>
        </div>
    </div>
    )
  }

  showSelectedAlarmInfo=(info)=>{
    return (
      <div className="manage-account-wrapper">
        <div className="manage-account">
          <h2>Alarm Info</h2>
          <label htmlFor="account-zone">Name</label>
          <span>{info.name}</span>
          <label htmlFor="account-zone">Id</label>
          <span>{info.alarmId}</span>
          <label htmlFor="account-zone">Enabled</label>
          <span>{info.en == 1 ? "Yes" : "No"}</span>
          <label htmlFor="account-zone">Notify Per Day</label>
          <span>{info.notifyPerDay}</span>
          <label htmlFor="account-zone">Notify How Often</label>
          <span>{info.notifyHowOften}</span>
          <label htmlFor="account-zone">Start Time</label>
          <span>{new Date(info.alarmTime * 1000).toLocaleString()}</span>
        </div>
    </div>
    )
  }

  showSelectedEmailInfo=(info)=>{
    let recipients = []
    for (let email in info.emails) {
      recipients.push(info.emails[email].email)
    }
    return (
      <div className="manage-account-wrapper">
        <div className="manage-account">
          <h2>Email Info</h2>
          <label htmlFor="account-zone">Title</label>
          <span>{info.title}</span>
          <label htmlFor="account-zone">Body</label>
          <span>{info.msg}</span>
          <label htmlFor="account-zone">Notify Count</label>
          <span>{info.notifyCount}</span>
          <label htmlFor="account-zone">Notify Per Day</label>
          <span>{info.notifyPerDay}</span>
          <label htmlFor="account-zone">Current</label>
          <span>{info.current}</span>
          <label htmlFor="account-zone">High</label>
          <span>{info.alHi}</span>
          <label htmlFor="account-zone">Low</label>
          <span>{info.alLo}</span>
          <label htmlFor="account-zone">Recipients</label>
          <span>{recipients.toString(", ")}</span>
        </div>
    </div>
    )
  }

  showAlarmPortal=(info)=>{
    return(
        <div
        >
        <h2 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.props.onChange("refresh",{element:"alarmPortal"})}}
        >Alarm Portal</h2>
        <div className="clearfloat"></div><br/>
        {this.showStatus("general")}
        <label style={{marginTop:"8px" , fontSize:"15px" , color:"#393D46"}}>Search</label>
        <input type="text" style={{width:"30%" , padding:"15px"}}
          value={this.props.oneSearch}
          onChange={e=>this.props.onChange("updSearch",{oneSearch:e.currentTarget.value})}
        />
        {this.showAccounts()}
        {this.showSites()}
        {this.showZones()}
        {this.showUsers()}
        {this.showStatus("selected")}
        {this.showSelectedAlarmTable()}
        {this.showSelectedEmailTable()}
        {this.showSelectedMailgunEmailTable()}
        </div>
    )
  }

  render() {
    if(this.state.loaded){
      return this.showAlarmPortal()
    } else{
      return <div>loading. . .</div>
    }
  }

}
  
  export default C18AlarmPortal;