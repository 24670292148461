import React from 'react';
import C18DateFormat00 from './C18DateFormat00'
import C18Button00 from './C18Button00'
import C18Anchor00 from './C18Anchor00'
import {getParamId,getParamId2,getParamId800,getPearlUsed,get800Used, wsTrans} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,getZoneIndex,loadSiteData,getChannelType,
  acctFeature
} from './C18utils'
import {getEquipmentStatus} from './C18EquipmentStatus00'
import {dbVals,getZoneControllers} from '../../components/utils/http';
import {cl,globs,dateToDisplayDate,getTime,constant} from '../../components/utils/utils';
import {pInd} from '../../components/utils/paramIds';

import history from "../../history"

class C18ZoneEquipment00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.getEquipment()
    this.state={
      mode:"sites",
      zInd:0,
      cols: 1,
      equipment:[
      {id:1, name:"Exhaust Variable Speed",system:"ON"},
      {id:2, name:"Water Valve 1",system:"ON"},
      {id:3, name:"North Roof Vents",system:"ON"},
      {id:4, name:"Water Pump",system:"ON - 60%"},
      {id:5, name:"Tank Fill Valve",system:"ON - 60%"},
      {id:6, name:"LED Grow Lights",system:"ON - 60%"},
      {id:7, name:"Floor Hot Water Valve",system:"OFF"},
      {id:8, name:"Fl Bl",system:"OFF"},
      {id:9, name:"Fl Nt",system:"OFF"},
      {id:11, name:"K Blo",system:"ON"},
      {id:12, name:"Fl Ki",system:"ON"},
      {id:13, name:"pH Up",system:"OFF"},
      {id:15, name:"pH Dn",system:"OFF"},
      {id:16, name:"Tank 2",system:"ON"},
      {id:17, name:"Gable Exhaust Fans",system:"OFF",alarm:true},
      {id:18, name:"Tank 4",system:"ON"},
      ],
      loaded:false,
      updateInterval: setInterval(this.update,1000),

      showEquipment:true,
      hideEquipment: globs.zonesInfo.info[getZoneIndex(this.props.parms.zone)]?.hideEquipment,
      zoneId: globs.zonesInfo.info[getZoneIndex(this.props.parms.zone)]?.zoneId,
      eqList: []
    }
//     setInterval(this.update,1000)
  }
  
  update=()=>{
//     cl("update")
    this.getEquipment()
  }
  
  componentWillUnmount=()=>{
    clearInterval(this.state.updateInterval)
  }
  
  getZoneIndex=(zoneId)=>{// returns the siteZoneIndex
    for(let i=0;i<globs.zonesInfo.info.length;i++){
      let z=globs.zonesInfo.info[i]
      if(z.zoneId==zoneId){
//         cl(z)
        return z.siteZoneIndex}
    }
  }
  
//   getPearlUsed=(zInd,zInfo)=>{
// //     cl("get pearl")
//     let xBoards=[]
//     let numOutId=getParamId2(zInfo.gatewayType,"configuration_expansion_boards","numOutputs")
//     let chIndex=getParamId2(zInfo.gatewayType,"configuration_expansion_boards","startChannelIndex")
//     let addIndex=getParamId2(zInfo.gatewayType,"configuration_expansion_boards","address")
//
//
//     let mult=pInd[1900].config_expansion_boards[2]
// //     cl(mult)
// //     cl(numOutId)
// //     cl(dbVals.z[zInd][240][addIndex])
// //     cl(chIndex)
//     for(let i=0;i<40;i++){
//       xBoards.push({
//         numOut:+((dbVals.z[zInd]||{})[240]||{})[numOutId+i*mult]||0,
//         chInd:+((dbVals.z[zInd]||{})[240]||{})[chIndex+i*mult]||0,
//       })
//     }
// //     cl(xBoards)
// //     cl(xBoards[0])
// //     if(xBoards[0].numOut==0){
// //       xBoards[0].numOut=8
// //       xBoards[0].chInd=0
// //       xBoards[1].numOut=4
// //       xBoards[1].chInd=8
// //     }
//     let ret=[]
//     xBoards.forEach(x=>{
//       if((+x.numOut)>0){
//         for(let i=0;i<x.numOut;i++){
//           ret.push(x.chInd+i)
//         }
//       }
//     })
// //     cl(xBoards)
//     return ret
//   }
  getUsedChannels=(zInd,zInfo)=>{
//     cl(zInfo)
//     let gotExpansion=false
    let zc=getZoneControllers(zInd)
//     cl(zc)
    let ret=[]
    let chUsedId=getParamId("configuration_channels","used")
    var used
    if(zInfo.virtual){
      let max=(zInfo.virtualType=="expLink4")?22:12
      for(let i=0;i<max;i++)
        if((i<12)||(i>=16))
          {ret.push(i)}
    }else{
//       cl(zInfo)
      if(zInfo.gatewayType==1900){return getPearlUsed(zInd,zInfo)}
      if(zInfo.gatewayType==800){return get800Used(zInd,zInfo)}
//         cl(ret)q
//         cl(ret)
//         ret=[]
//       }else{
      for(let i=0;i<160;i++){
        if(+zc[Math.floor(i/40)]){
          if((zInfo.gatewayType==1900)||(zInfo.gatewayType==800)){
            used=(i<8)?1:0
          }else{
            used=((dbVals.z[zInd]||{})[i]||{})[chUsedId]||0
          }
          if(+used){ret.push({chInd:i})}
        }
      }
//       }
    }
    return ret
  }

  getEquipment800=async(zInd,zInfo,chans)=>{
//     cl(dbVals)
    let chTypeId=getParamId800("igCE","channelType")
    let chNameId=getParamId800("igCE","channelName")
    let chPosId=getParamId800("igRE","position")
    let chOvrOne=getParamId800("igCE","oneChannelOverride")
    let chOvrTwo=getParamId800("igCE","twoChannelOverrideMode")
    let chOvrTwoPos=getParamId800("igCE","twoChannelOverridePosition")

    let proport=[constant.EQ_800_VENT, constant.EQ_800_CURTAIN]
//     cl(chTypeId)
//     cl(dbVals,zInd)
//     cl(chans)
    let equip=[]
    chans.forEach((c,i)=>{
      let chTypeInd=dbVals.z[zInd][i][chTypeId]
      let chType=getChannelType(zInfo.gatewayType, zInd,i)
      let ch=dbVals.z[zInd][i]
//       cl(ch)
      let pos=ch[chPosId]
      let off=(proport.includes(+(ch||{})[chTypeId]))?"0%":"OFF"
      let on=(proport.includes(+(ch||{})[chTypeId]))?"1%":"ON"
      if(pos==99){pos=100}
      let system=(pos!=undefined)?((pos==0)?off:((pos==1)?on:`${pos}%`)):"None"
      let status=getEquipmentStatus(zInfo.gatewayType,zInd,i,ch,0,0)
//       cl(i,chType)
//       cl(chTypeId)
//       cl(chNameId)
//       cl(dbVals.z[zInd][i])
      let eq={
        name:/*dbVals.z[zInd][i]*/ch[chNameId],
        type:chTypeInd,
        chType:chType,
        pos:pos,
        system:system,
        status:status,
        showInfo:(this.state.equipment[i]||{}).showInfo,
        id:i+1,
        unit:0,
        chan:i,
      }
//       cl(eq)
      equip[i]=eq
    })
//     cl(equip)
    this.setState({zInd: zInd, equipment: equip, loaded: true})

  }

//   get800Equipment=(zInfo)=>{
//     cl(zInfo)
// //     this.setState({zInd: zInfo.siteZoneIndex, equipment: equip, loaded: true})
//   }
  
  getEquipment=async()=>{
//     cl(this.props)
    let pa=this.props.parms
    await Promise.all([
      loadZonesInfo(),
      loadSiteData(this.props.parms.site),
    ])
//     cl(dbVals.z[2][0][508])
//     await loadZonesInfo()
//     await loadSiteData(this.props.parms.site)
    let zInd=this.getZoneIndex(pa.zone)// *not* the shared version!
    let zInfo=globs.zonesInfo.info[getZoneIndex(pa.zone)]
//     cl(zInfo)
//     if(zInfo.gatewayType==800){return this.get800Equipment(zInfo)}
    let chans=this.getUsedChannels(zInd,zInfo)// the actual channel ids.
//     cl(chans[0])
    if(zInfo.gatewayType==800){return this.getEquipment800(zInd,zInfo,chans)}
//     cl(zInfo.gatewayType)
//     cl(this.state)
    let proport=[constant.EQ_VENT, constant.EQ_CURT, constant.EQ_MIXV, constant.EQ_MZONE2, constant.EQ_GEN_PID]
    let chTypeId=getParamId("configuration_channels","channelType")
    let chNameId=getParamId("configuration_channels","channelName")
    let chPosId=getParamId("snapshot_channels","position")
    let chAnalogId=getParamId("configuration_channels","isAnalog")
//     cl(chAnalogId)
//     if(zInfo.gatewayType==1900){
// //       cl("got pearl")
//       cl(swPosId)
//     }

//     cl(zInfo)
//     cl(chans)
    let equip=[]
    let i2=1;
    // cl(chans)
    chans.forEach(c=>{
      // cl(c)
//       cl(dbVals.z[2][0][508])
      let i=(typeof c=="object")?c.chInd:c
//       cl(i)
       let zone=dbVals.z[zInd]||{}
       let isProp=proport.includes(+(zone[i]||{})[chTypeId])
       if(true||zone[i][chTypeId]!=0){
         var ch,pos,system,chType,reportedPos,auto
         if(true||zone){
          ch=zone[i]||{}
//           cl(ch)
          pos=+ch[chPosId]||0
          reportedPos=pos
//           if((zInfo.gatewayType==1900)&&(!isProp)){pos=c.relState}
//           cl(pos)
          let off=(isProp)?"0%":"OFF"
          let on=(isProp)?"1%":"ON"
          if(pos==99){pos=100}
          if(ch[chTypeId]==constant.EQ_NONE){
            system="None"
          }else{
            system=(pos!=undefined)?((pos==0)?off:((pos==1)?on:`${pos}%`)):"None"
            if(zInfo.gatewayType==1900){
              if(c.swPos){
                auto = "Auto "+system
                system=(c.relState?"Force ON":"Force OFF")
              }else{
                system="Auto "+system
              }
            }
          }
          // cl(system,c.swPos,c.relState)
//           cl(pos,system)
          chType=getChannelType(zInfo.gatewayType, zInd,i)
          // cl(chType)
          if(zInfo.gatewayType==1900){ch[chAnalogId]=c.type}// used later by getChannelType
//           let analog=(zInfo.gatewayType==1900)?c.type:ch[chAnalogId]
//           cl(analog)
          let chId=(zInfo.gatewayType==1900)?+c.chInd+1:i2
          i2++
          let status=getEquipmentStatus(pa.gwType,zInd,i,ch,c.swPos,c.relState)
          // if(status=="On "){system="ON"}
          // cl(status,system)
          let eq={
            id:chId,//i2++,
            name:ch[chNameId], 
            type:ch[chTypeId],
            analog:ch[chAnalogId],//analog,//
            chType:chType, 
            pos:pos, 
            reportedPos: reportedPos,
            system:system,
            swPos:c.swPos,
            relState:c.relState,
            showInfo:(this.state.equipment[i]||{}).showInfo,
            status:(chType=="channel_None")?"None":status,
            unit:0,//Math.floor(i/40),
            chan:i,//i%40,
            auto:auto,
          }
          // cl(eq)
//           equip.push(eq)
          equip[i]=eq
        }else{
          let eq={id:i2, name:`eq${i2}`, type:0, chType:0, pos:0, 
            system:0,showInfo:false,
          }
          i2+=1
//           equip.push(eq)
          equip[i]=eq
        }
      }
    })
//     for(let i=0;i<12;i++){
//     }
    // cl(equip)
    let eqList = this.makeEqList(equip)
    this.setState({zInd: zInd, equipment: equip, eqList: eqList.fullEquip, loaded: true})
  }

  makeEqList = (equipment) => {
    let twoChan = [
      constant.EQ_2ND_VENT, 
      constant.EQ_2ND_CURT, 
      constant.EQ_2ND_MIXV, 
      constant.EQ_2ND_MZONE2, 
      constant.EQ_2ND_GEN_PID, 
      constant.EQ_2ND_CRAVO
    ];
    let equip = [];
    let fullEquip = [];  // New array to store equipment that meets the twoChan condition
    let pa = this.props.parms;
    let zInfo = globs.zonesInfo.info[getZoneIndex(pa.zone)];
  
    equipment.forEach((e, i) => {
      const isTwoChan = twoChan.includes(+equipment[i].type);
      const shouldAddToEquip = 
        ((i % 2 !== 1) || !isTwoChan || e.analog) && 
        !(zInfo?.hideEquipment && equipment[i].chType === "channel_None");
  
      // Object to store equipment data
      let eqObj = {
        chType: e.chType,
        id: e.id,
        name: e.name,
        url: `/usa/c18/fui/${e.chType}/${this.state.zInd}-${e.unit}-${e.chan}-0`,
        type: e.type,
      };
  
      // Add to `equip` if it meets the `shouldAddToEquip` condition and doesn't already exist in `equip`
      if (shouldAddToEquip && !equip.some(item => item.id === e.id)) {
        equip.push(eqObj);
        fullEquip.push(eqObj);
      }
  
      // Add to `fullEquip` if it meets the `isTwoChan` condition and doesn't already exist in `fullEquip`
      if (isTwoChan && !fullEquip.some(item => item.id === e.id)) {
        fullEquip.push(eqObj);
      }
    });
  
    return { equip, fullEquip };
  }
    onClick=(type,e,eq,index)=>{
//     cl(type,eq,index)
// http://stage.c2.link4cloud.com:3114/fui/live/channel_None/0-0-1-0
// http://ngsg.link4cloud.com:3104/usa/c18/fui/unit_Input_Multipliers/0-0-0-0
    switch(type){
      case "equip":
        e.preventDefault()
//         cl(eq)
//         cl([eq.id,eq.chType])
//         cl(eq)
        let url=`/usa/c18/fui/${eq.chType}/${this.state.zInd}-${eq.unit}-${eq.chan}-0`
        history.push({
          pathname: url,
        },
        {
          eqList: this.state.eqList
        });
        // history.push(url)
        break
      case "info":// index is the equip array, *not* the id
//         let eqArr=Object.assign({},this.state.equipment)
//         cl(index)
        let eqArr=this.state.equipment.slice(0)
//         cl(eqArr)
        eqArr[index].showInfo=!eqArr[index].showInfo
        this.setState({equipment:eqArr})
        break
      default:
        break
    }
  }
  
  showOneEquipment=()=>{
    var twoChan
    var firstChan
    let pa = this.props.parms
//     cl(pa)
    if(pa.gwType==800){
      twoChan=[
      constant.EQ_800_VENT-constant.EQ_800_BASE,
      constant.EQ_800_CURTAIN-constant.EQ_800_BASE]
      firstChan=twoChan
    }else{
      twoChan=[constant.EQ_2ND_VENT, constant.EQ_2ND_CURT, constant.EQ_2ND_MIXV, constant.EQ_2ND_MZONE2, constant.EQ_2ND_GEN_PID,constant.EQ_2ND_CRAVO]
      firstChan=[constant.EQ_VENT, constant.EQ_CURT, constant.EQ_MIXV]
    }
//     cl(twoChan)
    let equip=[]
    let zInfo=globs.zonesInfo.info[getZoneIndex(pa.zone)]
    // let filteredEquip = this.state.showEquipment ? this.state.equipment : this.state.equipment.filter((e, i) => (((i%2!=1)||(!twoChan.includes(+this.state.equipment[i].type))||e.analog) && !(zInfo?.hideEquipment && this.state.equipment[i].chType=="channel_None" )))
//     cl(this.state.equipment)
//     cl(twoChan)
    this.state.equipment.forEach((e,i)=>{
//       cl(this.state.equipment[i].type)
        let onClass=(["Auto OFF","OFF","None","0%","Force OFF"].includes(e.system))?"":" on"
        let alarmClass=(e.alarm)?"alarm-on":""
      // cl(e)
//       if(e.status=="On "){e.system="ON"}
//         cl(i,this.state.equipment[i].type)
    let isFirst=i % 2 !== 1
    let isTwoChan=twoChan?.includes(+this.state.equipment[i].type)
if (
  (
    (i % 2 !== 1) ||
    (twoChan?.includes(+this.state.equipment[i].type) && i > 0 && !firstChan?.includes(+this.state.equipment[i - 1].type)) ||
    (!twoChan?.includes(+this.state.equipment[i].type)) || e.analog
  ) &&
  !(zInfo?.hideEquipment && this.state.equipment[i].chType === "channel_None")
) {
  equip.push(this.showEquipmentRow(alarmClass, e, onClass, i, i));
}
//     cl(twoChan?.includes(+this.state.equipment[i].type))
    })
    return (
      <table className="list">
        <tbody>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <td></td>
          </tr>
          {equip}
        </tbody>
      </table>
    );
  }
  
  showEquipmentOneColumn=()=>{
//     cl(globs.usersInfo.groupInfo)
    let hideGroup=(acctFeature("zoneGroups")&&globs.userData.session.groupId&&
      !globs.usersInfo.groupInfo?.allowSelectZone)?(
      <div id="zone_group_equipment" style={{position:"absolute",left:0,top:0,width:"100%",
        height:"100%",borderRadius:10,backgroundColor:"#00CC88",opacity:0.2,cursor:"pointer"}}
        onClick={e=>this.props.parms.onChange({cmd:"equipmentWrench", val:{wrenchCmd:"wrench"}})}
        />
    ):null
    return (
      <section
        id="equipments1800"
        className="link4 right-half"
        style={{ position: 'relative' }}
      >
        <C18DateFormat00
          parms={{
            leftString: 'Equipment',
            leftClass: 'title',
            type: 'wrench',
            ariaLabel: 'equipment settings',
            onChange: (val) =>
              this.props.parms.onChange({ cmd: 'equipmentWrench', val: val }),
            value: this.state.hideEquipment,
            onToggle: async () => {
              let he = !this.state.hideEquipment;
              let data = {
                zoneId: this.state.zoneId,
                hideEquipment: he,
              };
              await wsTrans("usa", {
                cmd: "cRest",
                uri: "/s/zones",
                method: "update",
                sessionId: globs.userData.session.sessionId,
                body: data,
              });
              this.setState({hideEquipment: he})
            },
          }}
        />
          <div className="equipment-list">{this.showOneEquipment()}</div>
        {hideGroup}
      </section>
    );
  }
  
  showEquipmentRow=(alarmClass,e,onClass,i,i2)=>{// i2 is the actual index
// new and added:
//   <tr><td><a href="" class="name">1. Exhaust Variable Speed</a></td><td><span class="on-off-indicator">OFF</span></td>
//     <td><C18Button00 type="button" href="" class="info-button" aria-label="info" disabled>i</C18Button00></td></tr> <!-- MODIFIED: ADDED DISABLED ATTRIBUTE IF NO INFO -->
//   <tr class="equip-hidden-info"><td colspan=3><span>pH Setpoint: 6.0, pH Reading: 5, alternate readings: 0,0, tank level: 0.3 gal, some other info</span></td></tr>
    let showInfo=(e.showInfo)?" show-details":""
    // cl(e)
//     progPos=(+e.pos)?1:0
    let override=((+e.reportedPos)!=e.relState)?
//       " forced":
      (e.swPos?" forced":""):
      (e.swPos)?" non-auto":" auto"
//     let forced=(e.swPos)?" forced":""
    // cl(override)
    if(e.type==constant.EQ_NONE){override=""}
    return(
      [
        <tr key={i+i2} className={alarmClass+showInfo}><td><C18Anchor00 to="" className="name" onClick={(ev)=>this.onClick("equip",ev,e)}>{`${e.id}. ${(e.name)?e.name:"None"}`}</C18Anchor00></td><td>
        <C18Anchor00 to="" className={`on-off-indicator${onClass}`} onClick={(ev)=>this.onClick("equip",ev,e)}>
          {`${e.system}`}</C18Anchor00></td><td>
        <C18Button00 type="button" className={`info-button${override}`} aria-label="info" onClick={(ev)=>this.onClick("info",ev,e,i2)}>i</C18Button00>
        </td></tr>,
        <tr key={(i+i2)+"b"} className="equip-hidden-info"><td colSpan="3"><span>{`${e.status} ${([" non-auto", " forced"].includes(override)) ? `, ${ e.auto}`: ""}`}</span></td></tr>
      ]
    )
  }
  
  showTwoEquipment=(col)=>{
    let equip=[]
    let equipLength=this.state.equipment.length
    let colLength=Math.floor((equipLength+1)/2)
    for(let i=0;i<colLength;i++){
      let i2=i+i+col;
      if(i2<equipLength){
        let e=this.state.equipment[i2]
//         cl(e.system)
        let onClass=(["OFF","0%","Force OFF"].includes(e.system)/*=="OFF"*/)?"":" on"
        let alarmClass=(e.alarm)?"alarm-on":""
        equip.push(this.showEquipmentRow(alarmClass,e,onClass,i,i2))
//         equip.push(
//           <tr key={i2} className={alarmClass}><td><span className="name">{`${e.id}. ${e.name}`}</span></td><td><span className={`on-off-indicator${onClass}`}>{e.system}</span></td><td><span className="info-button">i</span></td></tr>
//         )
      }else{
        equip.push(
          <tr key={i2}><td></td><td></td><td></td></tr>
        )
      }
    }
    return(
      <table className="list"><tbody>
        <tr><th>Name</th><th>System</th><td></td></tr>
        {equip}
      </tbody></table>
    )
  }
  
  showEquipmentTwoColumn=()=>{
    return(
      <section className="link4">
        <div className="section-header">
        <C18DateFormat00 parms={{
          leftString: dateToDisplayDate(new Date(),"mm/dd/yyyy h:mm ap"),
          leftClass: "date",
          type: "wrench",
          ariaLabel: "equipment settings",
          value: this.state.tileMode,
          valueId: "tileMode",
          onChange: (val)=>this.props.parms.onChange({cmd:"equipmentWrench", val:val})
        }}/>

        </div>
        <div className="two-column-equipment-list">
          <div className="left-column">
            {this.showTwoEquipment(0)}
          </div>
          <div className="right-column">
            {this.showTwoEquipment(1)}
          </div>
        </div>
      </section>
    )
  }
  
  render(){
//     cl(this.state)
//     cl(this.props)
    if(this.state.loaded){
      if(this.props.parms.cols==1){
        return this.showEquipmentOneColumn()
      }else{
        return this.showEquipmentTwoColumn()
      }
    }else{
      return <div id="content-area">loading equipment. . .</div>
    }
  }
}
      
export default C18ZoneEquipment00;
