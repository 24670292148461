import React from 'react';
import C18Anchor00 from './C18Anchor00'
import C18DateFormat00 from './C18DateFormat00'
import C18Button00 from './C18Button00'
import C18Input00 from './C18Input00'
import {wsTrans} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,loadGatewaysInfo,loadPrivsInfo,loadSiteData,loadSensorsInfo,
  getSiteName,loadAlarmsInfo,alLevel,getAlarmInfo,privs,makeSensors,setSiteZoneTypes,
  getCurrentSetpoint,
  onCheckout, getRecurlyAccount, updateSubscription, addToAdminLog, getZonesCount, getZoneDiscount, getTotal, loadUsersInfo, loadAccountInfo, checkOwner, checkAdmin,
  loadAddedSensorsInfo,loadMBInfo, verifyBilling,loadStationInfo,getUserIndex,
  setGroupId,acctFeature
} from './C18utils'
import history from "../../history"
import {cl,globs,dateToDisplayDate,constant,getTime, getTimeI, allZones, capitalize, round, allAddons, zoneTypes, recurlySubdomain,getDaysRemaining,show, calcTimezoneOffset,getRandomString} from '../../components/utils/utils';
import {dbVals} from '../../components/utils/http';
import {getParamId,getParamId800,tempUnit,lightUnit,initSensorIds} from '../utils/utils'
import config from '../../components/utils/config'


class C18ZonesListTiles00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.zltRefs=[]
    this.makeZones()
    this.listColumnCount=4
    
    this.tempStages=[// should use the version in usa/utils/utils
      "Heat 6",
      "Heat 5",
      "Heat 4",
      "Heat 3",
      "Heat 2",
      "Heat 1",
      "Normal",
      "Cool 1",
      "Cool 2",
      "Cool 3",
      "Cool 4",
      "Cool 5",
      "Cool 6",
    ]
    this.humStages=[// this should use usa/utils/utils
      "Normal",
      "Humidify",
      "DeHumidify 1",
      "DeHumidify 2",
    ]
    let type1800=[
      {id: "conTime", tab: "snapshots", col: "currentTime", type: 0},
      {id: "inTemp", tab: "snapshots", col: "inTemperature", type: 0},
      {id: "inHum", tab: "snapshots", col: "inHumidity", type: 0},
      {id: "inCo2", tab: "snapshots", col: "co2", type: 0},
      {id: "inLight", tab: "snapshots", col: "inLight", type: 0},
      {id: "setpoint", tab: "snapshots", col: "inTemperature", type: 0},
      {id: "tempStage", tab: "snapshots", col: "temperatureStage", type: 0},
      {id: "humStage", tab: "snapshots", col: "humidityStage", type: 0},
      {id: "heatSP", tab: "snapshots", col: "heatSetpoint", type: 0},
      {id: "coolSP", tab: "snapshots", col: "coolSetpoint", type: 0},
      {id: "humSP", tab: "snapshots", col: "humidifySetpoint", type: 0},
      {id: "dehumSP", tab: "snapshots", col: "dehumidifySetpoint", type: 0},
    ]
    let type800=[
      {id: "conTime", tab: "igR", col: "controllerTime", type: 0},
      {id: "inTemp", tab: "igR", col: "inTemperature", type: 0},
      {id: "inHum", tab: "igR", col: "inHumidity", type: 0},
      {id: "inCo2", tab: "igR", col: "inCo2", type: 0},
      {id: "inLight", tab: "igR", col: "inLight", type: 0},
      {id: "setpoint", tab: "igR", col: "inTemperature", type: 0},
      {id: "tempStage", tab: "igR", col: "temperatureStage", type: 0},
      {id: "humStage", tab: "igR", col: "humidityStage", type: 0},
      {id: "heatSP", tab: "igR", col: "heatSetpoint", type: 0},
      {id: "coolSP", tab: "igR", col: "coolSetpoint", type: 0},
      {id: "humSP", tab: "igR", col: "humidifySetpoint", type: 0},
      {id: "dehumSP", tab: "igR", col: "dehumidifySetpoint", type: 0},
    ]
    this.snapValues={
      "1800":type1800,
      "1900":type1800,
      "800":type800,
    }
//     this.snapValues=[
//       {id: "conTime", tab: "snapshots", col: "currentTime", type: 0},
//       {id: "inTemp", tab: "snapshots", col: "inTemperature", type: 0},
//       {id: "inHum", tab: "snapshots", col: "inHumidity", type: 0},
//       {id: "inCo2", tab: "snapshots", col: "co2", type: 0},
//       {id: "inLight", tab: "snapshots", col: "inLight", type: 0},
//       {id: "setpoint", tab: "snapshots", col: "inTemperature", type: 0},
//       {id: "tempStage", tab: "snapshots", col: "temperatureStage", type: 0},
//       {id: "humStage", tab: "snapshots", col: "humidityStage", type: 0},
//       {id: "heatSP", tab: "snapshots", col: "heatSetpoint", type: 0},
//       {id: "coolSP", tab: "snapshots", col: "coolSetpoint", type: 0},
//       {id: "humSP", tab: "snapshots", col: "humidifySetpoint", type: 0},
//       {id: "dehumSP", tab: "snapshots", col: "dehumidifySetpoint", type: 0},
//     ]
    this.state={
      tileMode: "grid-select",
      loaded: false,
      zones:[],
      md:false,
      zonePos:[],
      dragging:false,
      drag:{x:0,y:0},
      draggingIndex: null,
      seq:1,
    }
    this.checkZonesInt=setInterval(this.checkZones,20*1000)
    this.checkUpdateTimeInt=setInterval(this.checkUpdateTime,5*1000)
//     this.updateZoneTimesInt=setInterval(this.updateZoneTimes,10*1000)
    this.subscribe_zoneUpdate=globs.events.subscribe("newZoneData", this.updateZoneTimes)
    this.subscribe_resetZoneOrder=globs.events.subscribe("resetZoneOrder",this.resetZoneOrder)

    this.dark=((globs.device?.deviceTheme||"").toLowerCase().indexOf("dark")>=0)?1:0
    this.showSuper=privs("super","",constant.AREA_PRIVS_READ)
    this.showServer=["demo", "mjdemo", "mjdemoRemote"].includes(config.host)

    
    // this.showServer=false
//     cl(config.host)
//     cl(this.showServer)
  }
  handleDragStart = (index) => {
    // cl(this.state.zoneOrder);
    this.setState({ draggingIndex: index });
  };

  handleDragEnter = (index) => {
    const { draggingIndex, zones, zoneOrder } = this.state;

    if (draggingIndex === index) return;
    const newZoneOrder = zoneOrder.slice()
    // cl("old zone order")
    const [movedCard] = newZoneOrder.splice(draggingIndex, 1);
    newZoneOrder.splice(index, 0, movedCard);

    // cl("new zone order: " + newZoneOrder)
    this.setState({
      // zones: newZones,
      draggingIndex: index,
      zoneOrder: newZoneOrder,
    });
  };

  handleDragEnd = async (index) => {
    const { zoneOrder } = this.state;
    await this.saveZoneOrder(zoneOrder);
    this.setState({ draggingIndex: null });
    // cl(`saved zone order: from ${index} ` + zoneOrder)
  };


  componentWillUnmount=()=>{
    clearInterval(this.checkZonesInt)
    clearInterval(this.checkUpdateTimeInt)
//     clearInterval(this.updateZoneTimesInt)
    this.subscribe_zoneUpdate.remove()
    this.subscribe_resetZoneOrder.remove()
  }
  
  mySetState=(tag,obj)=>{
//     cl(tag)
    this.setState(obj)
  }

  countAlarms=(siteId,zInd)=>{
    let gai=globs.alarmsInfo.info
    let alarmCount=0
    for(let i=0;i<gai.length;i++){
      let a=gai[i]
//       cl(a.a)
      if((a.s==siteId)&&(a.z==zInd)&&(alLevel(a.a,a.s,a.z)<4)&&(globs.zonesInfo.sz2z[a.s][a.z])&&(!globs.zonesInfo.sz2z[a.s][a.z].virtual)){alarmCount++}
//         let ai=getAlarmInfo(a.a)
//         if(ai.level<=3){alarmCount++}
//       }
    }
    return alarmCount
  }
  
  addZoneInfo=async(zone)=>{
//     cl(zone.connected,zone.zoneName)
//     cl(zone.sensors)
    initSensorIds(zone.siteZoneIndex)
//     var sensors,zonex,tankNames,zIndx
//     cl("add")
//     cl([this.props.parms.site,zone.zoneId])
//     let gwType=1800
    let [sensors,zonex,tankNames,zIndx]=
      await makeSensors(this,zone.gatewayType,this.props.parms.site,zone.zoneId)
//     cl("make sensors done")
//     cl(sensors)
    zone.sensors=sensors
//     cl("add")
//     cl([zone.sensors,zonex,tankNames,zIndx])
    let zInd=zone.siteZoneIndex
//     cl(globs.userData.session)
//     cl(zone)
//     cl(zInd)
//     cl(dbVals.z)
//     cl(dbVals.zInd)
//     cl(dbVals.z[3])
//     cl(this.snapValues)
    let gwType=zone.gatewayType;
    (this.snapValues[gwType]||[]).forEach(s=>{
      var pid
      if(zone.gatewayType==800){
        pid=getParamId800(s.tab,s.col)
      }else{
        pid=getParamId(s.tab,s.col)
      }
//         cl(s)
//         cl(pid)
//       cl(zone)
//       cl(s)
//       cl(zInd)
//       cl(dbVals)
//       cl(dbVals.z)
//       cl(dbVals.z[zInd])
//       cl(dbVals.z[zInd][240])
      let v=((dbVals.z[zInd]||{})[240]||{})[pid]
      if(!v&&(v!=0)){v="--"}
      zone[s.id]=v
//       zone[s.id]=((dbVals.z[zInd]||{})[240]||{})[pid]||"--"
      if (s.id == "inTemp") zone[s.id] = Number(zone[s.id])
    })
//     cl(zone.gatewayType)
    let[sps,curSp,mins]=getCurrentSetpoint(zone.gatewayType,zInd)
    zone.name=zone.zoneName
    zone.unfinishedTasks="tbd"
    zone.setpoint=(1+curSp)//"--"
    zone.alarms=this.countAlarms(zone.siteId,zone.siteZoneIndex)
    zone.inTempUnit="\u00B0C"
    zone.inHumUnit="%"
    zone.inCo2Unit= "PPM"
    zone.inLightUnit= "W/m\u00B2"
  }
  
  setBreadcrumbs=()=>{
//     let siteName=getSiteName(this.props.parms.site)
    this.props.parms.onChange(
      {
        cmd: "breadcrumbs",
        data:
          {breadcrumbs: [
            {t:"Sites", url:"/usa/c18/sites"},
            {t:this.siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
          ]},
      },
    )
  }

  checkUpdateTime=()=>{
    this.setState({seq:this.state.seq+1})
  }
  
  checkZones=async()=>{
    globs.zonesInfo.got=false
    globs.zonesInfo.groups=null
//     cl("check zones")
    await loadZonesInfo()// forced reload
//     cl(globs.zonesInfo.info)
    let zx=globs.zonesInfo.info[31]
//     cl(zx.connected,zx.zoneName)
//     cl(globs.zonesInfo.info.length)
//     cl(globs.zonesInfo.info[0])
//     cl(globs.zonesInfo)
//     cl(globs.zonesInfo.info[0].inNet)
//     let inNetId=getParamId("configuration_controllers","isInNetwork")
//     cl(inNetId)
    let siteId=this.props.parms.site
    let zones=[]
    let gzi=globs.zonesInfo.info
    let gotZones=this.state.zones.length>0
    let dbgVal=gzi.filter(z=>{return z.gatewayId=="ZAHN0AUF9MGY4Y35"})[0]
    var groups,groupZones=[],showAll=true
    let zInd={}
    gzi.filter(z=>{return z.siteId==siteId}).forEach(z=>{
      zInd[z.zoneId]=z
    })
    // cl(zInd)
    if(acctFeature("zoneGroups")){
      let groups=(globs.zonesInfo.groups||[])
        .filter(g=>{return g.siteId==siteId})[0]?.groups
      let groupZones=[]
      if(groups){
        // cl(groups)
        let zoneList=gzi
          .filter(z=>{return (z.siteId==siteId)&&(!z.deleted)})
          .map(z=>{return z.zoneId})
        // cl(zoneList)
        let showGroups=groups.filter(g=>{
          let gotZones=g.zones.filter(z=>{return zoneList.includes(z)})
          groupZones=groupZones.concat(gotZones)
          return gotZones.length>0
        })
        // cl(showGroups)
        // cl(groupZones)
        zones=showGroups.map((g,i)=>{
//           cl(g)
          let groupTier="unlocked"
          g.zones.forEach(z=>{
            if(zInd[z]?.zoneTier!="unlocked"){groupTier="locked"}
          })
//           cl(groupTier)
//           g.zones.forEach(z=>{
//             if(z.zoneTier!="unlocked"){groupTier="locked"}
//           })
          this.zltRefs.push(React.createRef())
          return Object.assign({
          siteZoneIndex:i,
          siteId:siteId,
          connected:true,
          alarms:0,
          type:"group",
          zoneId:g.groupId,
          zoneTier:groupTier,
        },g)})
      }
      showAll=!globs.usersInfo.groupInfo?.notShowAllZones
    }
//     cl(globs.zonesInfo.groups)
//     cl(showAll)
    for(let i=0; i<gzi.length;i++){
      let z=gzi[i]
//       cl(z.connected,z.zoneName)
      if(!gotZones){z.updateTime-=60}// first time only, so the blue box won't show
//       cl(z)
//       cl(z.inNet)
//       let pearlInNet=(z.gatewayType==1900)?1:0
//       z.inNet=z.inNet||
//         ((dbVals.z[z.siteZoneIndex]||[])[240]||{})[inNetId]// was forced to 1, for Pearl
//       cl(z.inNet)
//       if(globs.userData.session.siteId=="6GDmHykPVoPKVS9O"){z.inNet=true}
//       cl(z)
      if(z.siteId==siteId&&(!z.deleted)&&(showAll||!groupZones.includes(z.zoneId))){
        await this.addZoneInfo(z)// adds snapshot data
        if(!this.zltRefs[zones.length]){this.zltRefs.push(React.createRef())}
        zones.push(z)
      }
    }
    // cl(zones)
    zones.sort((a,b)=>{
      if(a.gatewayZoneIndex>b.gatewayZoneIndex){return 1}
      if(a.gatewayZoneIndex<b.gatewayZoneIndex){return -1}
      return 0
    })
//     cl(zones)
    await this.mySetState("checkZones",{zones:zones})
  }

  updateZoneTimes=()=>{
//     cl("update zone")
//     cl(globs.contacts)
    let st=this.state
    let zones=st.zones.slice(0)
    let newData=false
    zones.forEach(z=>{
      let gcs=globs.contacts[z.siteId]
//       cl(gcs)
      if(gcs[z.siteZoneIndex]>z.updateTime){
        newData=true
        z.updateTime=gcs[z.siteZoneIndex]}
    })
//     cl("update")
    this.mySetState("updateZoneTimes",{zones:zones})
  }

  getZoneOrder=()=>{
//       cl(this.props.parms.site)
//       cl(globs.sitesInfo.info)
    let pa=this.props.parms
    let st=this.state
//     let zoneCount=globs.zonesInfo.info.filter(z=>{return z.siteId==pa.site}).length
//     cl(zoneCount)
    this.site=globs.sitesInfo.info.filter(s=>{return s.siteId==pa.site})[0]
    let zo=this.site?.zoneOrder||[]
    zo = zo.filter((z)=>{return z !== null})
//     cl("site zoneorder", this.site?.zoneOrder)
//     cl(zo)
    if (!zo.length || zo.length != st.zones.length) {
      zo = Array.from({length: st.zones.length}, (v, i) => i)
    }
    // cl(zo)
    // cl(st.zones)
    // cl(Array.from({length: st.zones.length}, (v, i) => i))
    // if(!zo){
//     cl(zo)
    zo.forEach((z,i)=>{zo[i]=+z})
    // cl(zo)
    return zo

//     cl(this.site)
  }
  
  makeZones=async()=>{
//     cl(this.props.parms)
    globs.userData.session.siteId=this.props.parms.site
//     cl(globs.userData.session.siteId)
    await loadSitesInfo()
    await loadGatewaysInfo()
    await loadSensorsInfo()
    await loadAddedSensorsInfo()
//     cl("loaded added sensors")
    this.siteName=getSiteName(this.props.parms.site)
    this.setBreadcrumbs()
    await loadAlarmsInfo()
    await loadPrivsInfo()
    await loadMBInfo()

//     cl(globs.zonesInfo)
    await loadSiteData(this.props.parms.site)
//     cl("await load zones info")
    await loadZonesInfo()
//     cl(globs)
//     cl(globs.zonesInfo.info[16].connected)
//     cl("load zones")
    globs.zonesInfo.info.forEach(z=>{
//       cl(z.updateTime)
      if(!globs.contacts[z.siteId]){globs.contacts[z.siteId]={}}
//       cl("set globs.contacts")
      globs.contacts[z.siteId][z.siteZoneIndex]=z.updateTime||0

    })
//     cl(globs.contacts)
    await loadStationInfo()
//     cl(globs.zonesInfo.info[0].inNet)
    await this.checkZones()
    let account = await getRecurlyAccount()

    let zoneOrder=this.getZoneOrder()
//     cl(zoneOrder)

    setSiteZoneTypes(this.props.parms.site)

    // load owner/admin for checkout
    await loadUsersInfo()
    await loadAccountInfo()
    let owner = checkOwner()
    let admin = await checkAdmin()
//     cl(globs.zonesInfo.info[0].inNet)
    
//     let proms=globs.zonesInfo.info.map(async z=>{
//       z.inNet=((dbVals.z[z.siteZoneIndex]||[])[240]||{})[inNetId]
//       if(globs.userData.session.siteId=="6GDmHykPVoPKVS9O"){z.inNet=true}
//       if(z.siteId==siteId&&(z.inNet||z.connected)){
//         await this.addZoneInfo(z)// adds snapshot data
//         zones.push(z)
//       }
//     })
//     await Promise.all(proms)
    
//     globs.zonesInfo.info.forEach(async z=>{
//       z.inNet=((dbVals.z[z.siteZoneIndex]||[])[240]||{})[inNetId]
//       if(z.siteId==siteId){
//         await this.addZoneInfo(z)// adds snapshot data
//         zones.push(z)
//       }
//     })
//     cl(zones)
//     cl("ss1")

    // this.mySetState("tag",{zones: zones, loaded: true})

    let sub = globs.subscriptionInfo.info
    let days = getDaysRemaining(new Date(globs.subscriptionInfo.info.end_date))
    let onTrial = sub.plan_code == "cloud2p0trialsub" && (getDaysRemaining(new Date(globs.subscriptionInfo.info.end_date)) >= 0)
    this.saveZoneInfo(false)// save the names to compare later
    this.topSensors = this.getTopSensors()
//     cl(this.topSensors)

    let idx = getUserIndex(globs.userData.session.userId)
    if (idx!=-1) {
      let u = globs.usersInfo.info[idx]
      this.timezone = (u?.timezone) ? u.timezone : "";
      if(!u.apiKey){
        // cl("new one")
        u.apiKey=getRandomString(32)
        let res= await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "update", 
          sessionId: globs.userData.session.sessionId, body: {userId:u.userId,apiKey:u.apiKey}})
      }
      this.apiKey = u.apiKey
    }
    this.mySetState("makeZones",{loaded: true, oldPlan: sub, currPlan: JSON.parse(JSON.stringify(sub)), account: account,
      displayCheckout: false, unlockAllZones: false, newConfig: [], unlockedAllZones: [], onTrial: onTrial, owner: owner, admin: admin,zoneOrder:zoneOrder})
//     cl(zones)
  }
  
//   showZoneTileAlarm=(s)=>{
//     if(s.alarms){
//       return(
//         <tr className="alarm-on"><th>Alarms 
// <button type="button" className="alarm-icon"
//           onClick={e=>{this.onChange("alarm",{event: e}); return false}}>!</button>
//           level 1-3</th><td>3</td></tr>
//       )
//     }else{
//       return(
//         <tr><th>Alarms</th><td>-</td></tr>
//       )
//     }
//   }

  onClickZone=async(e,z)=>{
//     cl("click zone")
    e.preventDefault()
    let st=this.state
    if(st.dragging){
//       cl("clear dragging")
//       await this.mySetState("onClickZone",{dragging:false})
      return}
//     cl(this.props)
//     cl(this.state)
//     cl(z.zoneTier)
//     cl(this.showSuper)
//  can enter zone if zone is unlocked, account is on trial, or user is a superuser
    if ((z?.zoneTier == zoneTypes["unlocked"] && !this.state.account.hasPastDueInvoice) || st.onTrial || this.showSuper || this.showServer) {
      // cl("proceeding into zone")
      history.push(`/usa/c18/sites/${this.props.parms.site}/zones/${z?.zoneId}`)
      if(acctFeature("zoneGroups")){setGroupId(z.groupId)}
    } else {
      // unlocking zone
      if (!st.displayCheckout) {
        // cl("unlocking zone")
        this.mySetState("onClickZone2",{zoneName: z.zoneName})
        this.unlockZone(z)
      }
    }
    // history.push(`/usa/c18/sites/${this.props.parms.site}/zones/${z.zoneId}`)
  }

  getZonePos=()=>{
    let st=this.state
    let zonePos=[]
    for(let i=0;i<st.zones.length;i++){
//       cl(i)
//       cl(this.zltRefs[i])
      zonePos.push(
        {x:this.zltRefs[i].current.offsetLeft,
          y:this.zltRefs[i].current.offsetTop}
      )
    }
    return zonePos
  }

  saveZoneOrder=async(zo)=>{
    let pa=this.props.parms
    let data={siteId:pa.site,zoneOrder:zo}
    // save locally
    this.site.zoneOrder = zo
//     cl(data)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "update",
      sessionId: globs.userData.session.sessionId, body: data})

  }

  newZoneOrder=async()=>{
    let st=this.state
//     cl(st)
    if(!st.zonePos.length||!st.zoneOrder){return}
    // cl(st)
    // cl(st.zonePos)
    let scrInd=st.dragStart.i
    let orgPos=st.zonePos[scrInd]
    let dx=st.drag.x-st.dragStart.x
    let dy=st.drag.y-st.dragStart.y
    let nx=orgPos.x+dx
    let ny=orgPos.y+dy
//     cl(nx,ny)
//     cl(orgPos)
    var newInd=scrInd
//     for(let i=0;i<st.zonePos.length;i++){
    for(let i=st.zonePos.length-1;i>=0;i--){
      let zp=st.zonePos[i]
//       let ind=st.zoneOrder[i]// the actual index
      if((zp.y<ny)&&(zp.x<nx)){
        newInd=i
        break
      }
    }
    let zo=st.zoneOrder.slice(0)
    zo.splice(newInd+1,0,zo[scrInd])
    if(scrInd>newInd){scrInd+=1}
    zo.splice(scrInd,1)
    await this.mySetState("newZoneOrder",{zoneOrder:zo})
    await this.saveZoneOrder(zo)

  }

  md=(e,i)=>{
//     cl("down")
//     cl(e,i)
    if(!this.zltRefs[i]){return}
    this.zltRefs[i].current.style.zIndex=1
    this.mySetState("md",{dragStart:{i:i,x:e.clientX,y:e.clientY},md:true})
  }

  mu=async (e)=>{
    let st=this.state
//     cl("mouse up",st.md)
    // cl(e)
    if(st.md){
      this.zltRefs[st.dragStart.i].current.style.zIndex=0
      await this.newZoneOrder()
      await this.mySetState("mu",{md:false,drag:{x:0,y:0},dragging:false})
//       cl(this.state)
//       cl(this.state.dragging)// still when when drag doesn't end'
//       cl(st.zoneOrder)
    }
  }

  dragZone=(e)=>{
//     cl(e)
    this.mySetState("dragZone",{drag:{x:e.clientX,y:e.clientY}})
  }

  mm=(e)=>{
//     cl("x")
    e.preventDefault()
    let st=this.state
    var dx,dy
//     cl(st.md)
    if(st.md){
//       cl(e)
      dx=e.clientX-st.dragStart.x
      dy=e.clientY-st.dragStart.y
    }
//     cl(dx,dy)
//     cl(dx,dy,st.dragging)
    if(((dx*dx+dy*dy)>100)&&(!st.dragging)){
      let zonePos=this.getZonePos()
//       cl(zonePos)
      this.mySetState("mm",{dragging:true,zonePos:zonePos})
    }
//     cl(dx,dy)
    if(st.dragging){
//       cl("dd")
      this.dragZone(e)
    }
  }

  showGroupMembers=(g)=>{
//     cl(g.zones)
    let rows=g.zones.map((z,i)=>{
      let zone=globs.zonesInfo.info.filter(zo=>{return zo.zoneId==z})[0]
      if(zone){
        return(
          <tr key={i}><td width="10"></td><td style={{textAlign:"left"}} colSpan="2">{zone.zoneName}</td></tr>
        )
      }

    })
    return rows
//     return(
//       <tr><td width="10"></td><td style={{textAlign:"left"}} colSpan="2">mem</td></tr>
//     )
  }
  
  showTileSensors=(z,zInd)=>{
//     cl(z)
    // check if trial is still active
    if(z?.type=="group"){return this.showGroupMembers(z)}
    if(z?.sensors){
      let tu=tempUnit(zInd)
      let da=new Date(1000*((+z.conTime)/*+480*60*/))
      let zTime=dateToDisplayDate(da,"mm-dd hh:mn",0)
//       cl(zTime)
//       cl(tu)
      return z.sensors.map((s,i)=>{
        if(s.id.substr(2)=="zoS"){
//           cl(s)
          return(
            <React.Fragment key={i}>
            <tr key={i+"ct"}>
              <th>Cont Time</th>
              {
              (z.zoneTier == zoneTypes["unlocked"] || this.state.onTrial || this.showSuper || this.showServer) ?
              <td>{zTime}</td>
              :
              <td></td>
              }
            </tr>
            <tr key={i+"c"}>
              <th>Setpoint</th>
              {
              (z.zoneTier == zoneTypes["unlocked"] || this.state.onTrial || this.showSuper || this.showServer) ?
              <td>{s.name}</td>
              :
              <td></td>
              }
            </tr>
            <tr key={i+"a"}>
              <th>{this.tempStages[z.tempStage]}</th>
              {
              (z.zoneTier == zoneTypes["unlocked"] || this.state.onTrial || this.showSuper || this.showServer) ?
              <td>{`${z.heatSP}-${z.coolSP} ${tu.t}`}</td>
              :
              <td></td>
              }
            </tr>
            <tr key={i+"b"}>
              <th>{this.humStages[z.humStage]}</th>
              {
              (z.zoneTier == zoneTypes["unlocked"] || this.state.onTrial || this.showSuper || this.showServer) ?
              <td>{`${z.humSP}-${z.dehumSP} %`}</td>
              :
              <td></td>
              }
            </tr>
            </React.Fragment>
          )
          
        }else{
          return(
            <tr key={i}><th>{s.name2||s.name}</th>
            {
            (z.zoneTier == zoneTypes["unlocked"] || this.state.onTrial || this.showSuper || this.showServer) ?
            <td>{(s.id=="zoS")?"":`${s.val} ${s.cUn}`}</td>
            :
            <td></td>
            }  
            </tr>
          )
        }
      })
    }
  }
  
  showListSensorHeads=(topSensors)=>{
    return (
      // <React.Fragment key={i}>
      <React.Fragment>
        <th>Name</th>
        <th>Last Contact</th>
        <th>Setpoint</th>
        <th>Temp Stage</th>
        <th>Hum Stage</th>
        {topSensors.map((t)=>{
          return <th>{t}</th>
        })}
      </React.Fragment>
    )
  }
    
  showListSensors=(z,zInd,topSensors)=>{
    // if(z.sensors){
      let tu=tempUnit(zInd)
      let da=new Date(1000*((+z.conTime)/*+480*60*/))
      let zTime=dateToDisplayDate(da,"mm-dd hh:mn",0)
      // do zone setpoint
      let sn = (z.sensors) ? z.sensors?.filter((s)=>s.id.substr(2) == "zoS") : []
      let zos = (sn && sn[0] && z.zoneTier == zoneTypes["unlocked"] || this.state.onTrial || this.showSuper || this.showServer) ?
      // <td>{s.name}</td>
      <td>{`${sn[0]?.name !== undefined ? sn[0].name : "--"} ${sn[0]?.cUn||""}`}</td>
      :
      <td></td>
      return (
        // <React.Fragment key={i}>
        <React.Fragment>
          {
            (z.zoneTier == zoneTypes["unlocked"] || this.state.onTrial || this.showSuper || this.showServer) ?
            <td>{zTime}</td>
            :
            <td></td>
          }
          {
            zos
          }
          {
          (z.zoneTier == zoneTypes["unlocked"] || this.state.onTrial || this.showSuper || this.showServer) ?
          // <td>{s.name}</td>
          <td>{this.tempStages[z.tempStage]}</td>
          :
          <td></td>
          }
          {/* <th>{this.tempStages[z.tempStage]}</th> */}
          {/* <th>{this.humStages[z.humStage]}</th> */}
          {
          (z.zoneTier == zoneTypes["unlocked"] || this.state.onTrial || this.showSuper || this.showServer) ?
          // <td>{`${z.heatSP}-${z.coolSP} ${tu.t}`}</td>
          <td>{this.humStages[z.humStage]}</td>
          :
          <td></td>
          }
          {
            topSensors.map((t)=> {
              let sn = (z.sensors) ? z.sensors.filter((s)=>{
                let name = s.name2||s.name
                return name == t
              }) : []
              let ret = (sn && sn[0] && z.zoneTier == zoneTypes["unlocked"] || this.state.onTrial || this.showSuper || this.showServer) ?
              <td>{
                `${sn[0]?.val !== undefined ? sn[0].val : "--"} ${sn[0]?.cUn||""}`
              }
              </td>
              :
              <td></td>
              return ret
            })
          }
        </React.Fragment>
      )
          // else {
          //   return(
          //     <td key={i}>{(s.id=="zoS")?"":`${s.val !== undefined ? s.val : "--"} ${s.cUn}`}</td>
          //   )
          // }
    // }
  }
  
  showZoneTiles=()=>{
//     cl("show zone tiles")
    let st=this.state
//     cl(st)
    let now=getTime()
    let zones=[]
//     cl(st.zones)
//     cl(st)
    for(let i=0;i<st.zones.length;i++){
      let zo=st.zoneOrder
      let pos=i
      // cl(zo)
      if(zo&&(zo[i]||(zo[i]==0))){
        // cl("zone order present")
        pos=zo[i]
      }
      // cl([i, pos])
      let z=st.zones[pos]
//       cl(st)
//         cl(z.connected,z.zoneName)
      // cl([i,z?.siteZoneIndex])
//       cl(z)
//       let z=st.zones[(zo)?zo[i]:i]
//     }
//     st.zones.forEach((z,i)=>{
//       cl(i,z)
//       cl(z.virtual)
      let bgColor=(z?.virtual)?(this.dark)?"#282818":"#F6F7E0":null
      if(z?.type=="group"){bgColor=(this.dark)?"#008379":"#CCFFFF"}
      let now=getTimeI()
//       cl(z.updateTime)
      let elapse=now-z?.updateTime//||60
      let newUpdate=elapse<900
//       cl(elapse)
      if(!elapse&&(elapse!=0)){elapse=60}
      var lastTime
//       cl(elapse)
      if(elapse>3600){
        if(elapse>7*86400){
          if(elapse>31*86400){
            lastTime=`${Math.floor(elapse/(31*86400))} Months`
          }else{
            lastTime=`${Math.floor(elapse/(7*86400))} Weeks`
          }
        }else{// hrs or days
          if(elapse>86400){
            lastTime=`${Math.floor(elapse/86400)} Days`
          }else{
            lastTime=`${Math.floor(elapse/3600)} Hours`
          }
        }
      }else{
        if(elapse>60){
          lastTime=`${Math.floor(elapse/60)} Minutes`
        }else{
          lastTime=`${Math.floor(elapse)} Seconds`
        }
      }
      let opacCycle=(z.gatewayType==800)?30:60
      let opac=(opacCycle-elapse)/opacCycle
//       cl(opac)
      if(opac<0){opac=0}
//       cl(elapse,opac)
      // cl(z.zoneId)
      // cl(privs("zone",z.zoneId,constant.AREA_PRIVS_READ))
      if(privs("zone",z?.zoneId,constant.AREA_PRIVS_READ)){
//         cl("privs")
        let zInd=z?.siteZoneIndex
        var dragX=0,dragY=0
//         if(st.dragging&&(i==st.dragStart.i)){
// //           cl("dragging")
// //           cl(st.drag)
//           dragX=st.drag.x-st.dragStart.x
//           dragY=st.drag.y-st.dragStart.y
//         }
//         cl(z)
// style={{backgroundColor:(now-z.updateTime>3600)?"#DDDDDD":"white"}}        
//             onMouseUp={e=>{
//               cl("mu")
//               if((st.dragging)&&(i!=st.dragStart.i)){
//                 cl("click zone")
//                 this.onClickZone(e,z)}
//             }}
//         cl(z.connected,z.zoneName)
        zones.push(
          <div ref={this.zltRefs[i]} key={i} id="zoneTile"
            className={`controller-container${
            ((z?.connected||z?.virtual||newUpdate))?"":" not-synced"}`}
            style={{position:"relative",left:dragX,top:dragY}}
          >
            <div className={`controller-wrapper${(z?.alarms)?" alarm-on":""}`} aria-label={z?.name}
            style={{backgroundColor:bgColor}}
            onClick={e=>{this.onClickZone(e,z)}}
            // onMouseDown={(e) => this.md(e, i)}
            draggable
            onDragStart={() => this.handleDragStart(i)}
            onDragEnter={() => this.handleDragEnter(i)}
            onDragEnd={() => this.handleDragEnd(i)}
            >
              
              <table><tbody>
                <tr><th colSpan="2">

                <h2>
                <C18Anchor00 to="" key={i} onClick={e=>{this.onClickZone(e,z)}}>
                  {z?.name}
                </C18Anchor00>
                <div style={{display:"inline-block",marginLeft:10,width:14,opacity:opac,
                  height:14,backgroundColor:"#0080FF"}}
                  title={`updated ${elapse} seconds ago`}
                  />
                {((z?.zoneTier!=zoneTypes["unlocked"] || this.state.account.hasPastDueInvoice) &&
//                   (z.type!="group")&&
                  !this.state.onTrial && !this.showSuper && !this.showServer)&&
                  <button type="button" className="material-icons-outlined lock-icon">lock</button>
                }
                {(z?.alarms!=0)&&
                  <button type="button" className="alarm-icon"
                  onClick={e=>{this.onChange("alarm",{zoneId:z.zoneId,event: e}); return false}}>!</button>
                  
                }
                </h2>
                
                </th></tr>
                {this.showTileSensors(z,zInd)}
                {false&&
                  <tr><th>Unfinished Tasks</th><td>{z.unfinishedTasks}</td></tr>
                }
              </tbody></table>
            </div>
            <span className="material-icons-outlined not-connected-icon"
              title={`Zone is Offline for ${lastTime}`}>report_problem</span>
          </div>
        )
      }
    }//)
    // cl(zones)
    return (
      <div className="controller-grid"
        // onMouseUp={
        //   this.mu}
        // onMouseMove={this.mm}
      >
          {zones}
      </div>
    )
  }
  
  showZoneListAlarms=(s)=>{
    if(s.alarms){
      return(
        <td>level 1-3<span className="alarm-icon">!</span></td>        
      )
    }else{
      return(
        <td>-</td>
      )
    }
    
  }

  getListCount=(z)=>{
    let curr = 1
    if(privs("zone",z?.zoneId,constant.AREA_PRIVS_READ)){
      curr = curr + Math.min(z.sensors?.length||0, this.listColumnCount)
      if (z.zoneTier!=zoneTypes["unlocked"] && !this.state.onTrial && !this.showSuper && !this.showServer) curr++
      // check if has zone setpoint
      if (z.sensors) {
        z.sensors.forEach((s,i)=>{
          if(i<this.listColumnCount){
            // check for setpoint val
            if (s.id.substr(2) == "zoS") {
              curr = curr + 3
            }
          }
        })
      }
    }
    return curr
  }

  getMaxListElems=()=>{
    let st=this.state
    let max = 1
    for(let i=0;i<st.zones.length;i++){
      let z=st.zones[i]
      max = Math.max(max, this.getListCount(z))
    }
    return max
  }

  getTopSensors=()=>{
    let st=this.state
    let sensorCount = {}
    for(let i=0;i<st.zones.length;i++){
      let z=st.zones[i]
      if(z.sensors){
        z.sensors.forEach((s,i)=>{
          if (s.id.substr(2) != "zoS") {
            if (sensorCount[s.name2||s.name]) {
              sensorCount[s.name2||s.name]+= 1
            } else {
              sensorCount[s.name2||s.name] = ["inT", "inH", "ouT", "ouH"].includes(s.id) ? 2 : 1
            }
          }
        })
      }
    }

    // make array from count
    // Create items array
    let items = Object.keys(sensorCount).map(function(key) {
      return [key, sensorCount[key]];
    });

    // Sort the array based on the second element
    items.sort(function(i, j) {
      return j[1] - i[1];
    });
    // Create a new array with only the first 5 items
    return items.map((i) => i[0]).slice(0, 5)
  }

  resetZoneOrder=async(cmd) => {
    // cl("🚀 ~ newZoneOrder=async ~ cmd:", cmd)
    // this.setState({zoneOrder})
    let zoneIndexes = Array.from({length: this.state.zones.length}, (v, i) => i)
    // cl(zoneIndexes)
    await this.saveZoneOrder(zoneIndexes)
    this.setState({zoneOrder:zoneIndexes});
    // 
  }

  showZoneList=()=>{
    let zones=[]
    let st=this.state
    // let max = this.getMaxListElems()
    let topSensors = this.topSensors
    
    for(let i=0;i<st.zones.length;i++){
      let zo=st.zoneOrder
      let pos=i
      // cl(zo)
      if(zo&&(zo[i]||(zo[i]==0))){pos=zo[i]}
      // cl([i, pos])
      let z=st.zones[pos]
      // let cnt = this.getListCount(z)
//       cl(i,z.updateTime)
      // cl(z)
//       let z=st.zones[(zo)?zo[i]:i]
//     }
//     st.zones.forEach((z,i)=>{
//       cl(i,z)
//       cl(z.virtual)
      let bgColor=(z?.virtual)?(this.dark)?"#282818":"#F6F7E0":null
      if(z.type=="group"){bgColor="#CCFFFF"}
      let now=getTimeI()
//       cl(z.updateTime)
      let elapse=now-z?.updateTime//||60
      let newUpdate=elapse<900
//       cl(elapse)
      if(!elapse&&(elapse!=0)){elapse=60}
      var lastTime
//       cl(elapse)
      if(elapse>3600){
        if(elapse>7*86400){
          if(elapse>31*86400){
            lastTime=`${Math.floor(elapse/(31*86400))} Months`
          }else{
            lastTime=`${Math.floor(elapse/(7*86400))} Weeks`
          }
        }else{// hrs or days
          if(elapse>86400){
            lastTime=`${Math.floor(elapse/86400)} Days`
          }else{
            lastTime=`${Math.floor(elapse/3600)} Hours`
          }
        }
      }else{
        if(elapse>60){
          lastTime=`${Math.floor(elapse/60)} Minutes`
        }else{
          lastTime=`${Math.floor(elapse)} Seconds`
        }
      }
      if(privs("zone",z?.zoneId,constant.AREA_PRIVS_READ)){
        let zInd=z.siteZoneIndex
  //       cl(s)
        let alarmClass=(z.alarms)?"alarm-on":""
        // don't do table headers for each zone
        zones.push(
          <React.Fragment key={i}>
          <tr
            draggable
            onDragStart={() => this.handleDragStart(i)}
            onDragEnter={() => this.handleDragEnter(i)}
            onDragEnd={() => this.handleDragEnd(i)}
            style={{backgroundColor:bgColor}}
            key={i}
            onClick={e=>{this.onClickZone(e,z)}}
            className={`${alarmClass}${(z.connected)?"":" not-synced"}`}>
            <td>
              <div className='name'>
              <C18Anchor00 to="" className="name" onClick={e=>{this.onClickZone(e,z)}}>
               {z.name}
              </C18Anchor00>
              <span className="material-icons-outlined not-connected-icon" title={`Zone is Offline for ${lastTime}`}>report_problem</span>
              {(z.alarms!=0)&&
                <button type="button" className="alarm-icon"
                  onClick={e=>{this.onChange("alarm",{zoneId:z.zoneId,event: e}); return false}}>!</button>
              }
              </div>
            </td>
            {this.showListSensors(z,zInd, topSensors)}
            {false&&
              <td>{z.unfinishedTasks}</td>
            }
            {(z.zoneTier!=zoneTypes["unlocked"] && !this.state.onTrial && !this.showSuper && !this.showServer) && 
              <td>
              <button type="button" className="material-icons-outlined lock-icon">lock</button>
             </td>
            }
            {false&&
                <td><a href="" className="create-grow-journal-entry material-icons-outlined" aria-label="make grow journal entry">
                  note_alt
                </a>
                </td>
            }
            {/* {false&& this.fillList(max, cnt)} */}
          </tr>
          </React.Fragment>
        )
      }
    }
    return (
      <div className="controller-list">
        <table className="list"><tbody>
          {this.showListSensorHeads(topSensors)}
          {zones}
        </tbody></table>
      </div>
    )
  }

  fillList=(max, cnt)=>{
    let tds = []
    for (let i = 0; i < max - cnt; i++) {
      tds.push(<td></td>)
    }
    return tds
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "format":
        if("cmd" in vals){
          cl(vals)
          cl(this.props)
          cl(this.state)
          cl("got cmd")
          let pa=this.props.parms
          history.push(`/cs/physView/sites/${pa.site}`)
          return
        }
//         cl("ss2")
        this.mySetState("onChange",vals)
        break
      case "alarm":
        let e=vals.event
        e.stopPropagation()
        history.push(`/usa/c18/sites/${this.props.parms.site}/zones/${vals.zoneId}/alarmLog`)
        break
    }
//     cl(vals)
  }
  
  showZones=()=>{
//     cl(this.state.tileMode)
    return (this.state.tileMode=="grid-select")?this.showZoneTiles():this.showZoneList()
  }

  /* BEGIN SUBSCRIPTION METHODS */

  unlockZone=async(zone)=>{
    // set zone count to licenses needed (remaining)
    let oldLicenses = this.state.oldPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]] 
    let oldUnlockCount = globs.zonesInfo.info.filter((z) => z.zoneTier == zoneTypes["unlocked"]).length
    let existingLicenses = oldLicenses - oldUnlockCount
    this.state.newConfig.push({zoneId: zone.zoneId, zoneTier: zoneTypes["unlocked"]})
    if (existingLicenses > 0) {
      // popup shows when disabled zone unlocked with slots left
      this.state.currPlan.add_ons.zone_qty.zone[zoneTypes["locked"]]--
      this.mySetState("unlockZone",{displayCheckout: true})
    } else {
      // add to new config object
      // make new plan
      this.state.currPlan.add_ons.zone_qty.zone[zoneTypes["locked"]]--
      this.state.currPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]]++
      let planInfo = await onCheckout(this.state.currPlan)
      // cl(planInfo)
      if (planInfo.plan && planInfo.preview) {
        planInfo.displayCheckout = true
        this.mySetState("unlockZone2",planInfo)
        // saving happens upon successful checkout
      } else {
        // plan generation error
      } 
    }
    
  }
  
  // setZoneCount=(oldZoneTier, newZoneTier)=>{// should not be changing zonesInfo!
  //     // don't reduce count of unlocked - no downgrading except on manage subscription page
  //     this.state.currPlan.add_ons.zone_qty.zone[oldZoneTier]--
  //     this.state.currPlan.add_ons.zone_qty.zone[newZoneTier]++
  //     // only add to unlocked zone count if the number of new zones is equal to new licenses
  //     cl(this.state.currPlan.add_ons.zone_qty)
  //     // add zone to modified
  //     return
  // }

  getZoneTier=(zoneId)=>{// should not be changing zonesInfo!
    let gzi=globs.zonesInfo.info
    for(let i=0;i<gzi.length;i++){
      if(gzi[i].zoneId==zoneId){
        return gzi[i].zoneTier
      }
    }
  }

  // only done iff plan is confirmed
  saveZoneTier=(zoneId,zoneTier)=>{// should not be changing zonesInfo!
    let gzi=globs.zonesInfo.info
    for(let i=0;i<gzi.length;i++){
      if(gzi[i].zoneId==zoneId){
        gzi[i].zoneTier=zoneTier
        return
      }
    }
  }

  unlockAllZones=async(unlock)=>{
    // recalculate plan with all zones in current site unlocked
    let selZones = globs.zonesInfo.info.filter(z => (z.siteId == this.props.parms.site))
    // calculate zones in currently selected site that are not currently selected
    if (unlock) {
      let uaZones = []
      cl(this.state.newConfig)
      selZones.forEach((z) => {
        cl(z)
        let skip = false
        this.state.newConfig.forEach((n)=>{
          if (z.zoneId == n.zoneId) {
            skip = true
            return
          }
        })
        cl(skip)
        if (!skip && z.zoneTier != zoneTypes["unlocked"]) {
          uaZones.push({zoneId: z.zoneId, zoneTier: zoneTypes["unlocked"], oldZoneTier: z.zoneTier})
        }
      })
      // how many licenses needed
      cl(uaZones)
      let oldLicenses = this.state.oldPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]] 
      let oldUnlockCount = globs.zonesInfo.info.filter((z) => z.zoneTier == zoneTypes["unlocked"]).length
      let newUnlockCount = this.state.newConfig.filter((z) => z.zoneTier == zoneTypes["unlocked"]).length
      let existingLicenses = oldLicenses - oldUnlockCount
      let remainingLicenses = existingLicenses - newUnlockCount
      remainingLicenses = (remainingLicenses >= 0) ? remainingLicenses - uaZones.length : remainingLicenses - (uaZones.length - newUnlockCount)
      let neededLicenses = (remainingLicenses < 0) ? Math.abs(remainingLicenses) : 0 
      cl([remainingLicenses, neededLicenses])
      if (neededLicenses > 0) {
        this.state.currPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]] += neededLicenses
      }
      this.state.currPlan.add_ons.zone_qty.zone[zoneTypes["locked"]] -= uaZones.length
      this.mySetState("tag",{unlockedAllZones: uaZones, neededLicenses: neededLicenses})
    } else {
      // revert
      this.state.currPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]] -= this.state.neededLicenses
      this.state.currPlan.add_ons.zone_qty.zone[zoneTypes["locked"]] += this.state.unlockedAllZones.length
      this.mySetState("tag",{unlockedAllZones: [], neededLicenses: 0})
    }
    // update plan state
    // cl(this.state.currPlan)
    
    let planInfo = await onCheckout(this.state.currPlan)
    if (planInfo.plan && planInfo.preview) {
      planInfo.displayCheckout = true
      planInfo.unlockAllZones = unlock
      this.mySetState("tag",planInfo)
      // saving happens upon successful checkout
    } else {
      // plan generation error
      cl("plan generation error")
    }
  }

  onConfirm = async (needLicense) => {
    // check if buying new plan
    if (needLicense) {
      let account = await getRecurlyAccount()
      // save zone info
      this.state.newConfig.forEach((z)=>{
        this.saveZoneTier(z.zoneId, z.zoneTier)
      })
      // save unlocked zones info
      if (this.state.unlockedAllZones)  {
        this.state.unlockedAllZones.forEach((z) => {
          this.saveZoneTier(z.zoneId, z.zoneTier)
        })
      }
      this.saveZoneInfo(true)
      if (account.billingInfo) {
        // verify billing info
        let verification = await verifyBilling()
        cl(verification)
        if (verification) {
          let updated = await updateSubscription(this.state.plan, this.state.currPlan.add_ons)
          // refresh page
          this.mySetState("tag",{displayCheckout: false, plan: null, preview: null, newConfig: [], unlockedAllZones: [], unlockAllZones: false})
          cl("***********************reload")
          window.location.reload()
        } else {
          // popup telling user that their billing info needs to be changed + link
        }
      } else {
        cl("no billing info")
        // save zones in 'new config' to be filled in later with new subs
        // takes to recurly hosted payment page (if no payment info)
        cl("***********************reload")
        window.location.href = `https://${recurlySubdomain}.recurly.com/subscribe/${this.state.plan.code}/${account.code}/${account.username}`
        this.mySetState("tag",{displayCheckout: false, plan: null, preview: null, newConfig: [], unlockedAllZones: [], unlockAllZones: false})
      }
    } else {
      // save zone info
      this.state.newConfig.forEach((z)=>{
        this.saveZoneTier(z.zoneId, z.zoneTier)
      })
      // save unlocked zones info
      if (this.state.unlockedAllZones)  {
        this.state.unlockedAllZones.forEach((z) => {
          this.saveZoneTier(z.zoneId, z.zoneTier)
        })
      }
      this.saveZoneInfo(true)
      // refresh page
      this.mySetState("tag",{displayCheckout: false, plan: null, preview: null, newConfig: [], unlockedAllZones: [], unlockAllZones: false})
      cl("***********************reload")
      window.location.reload()
    }
  }

  onCancel = () => {
    // TODO un-disable save button
    // reset all zones that were going to be unlocked
    this.mySetState("tag",{displayCheckout: false, plan: null, preview: null, newConfig: [], unlockedAllZones: [], unlockAllZones: false,
      currPlan: JSON.parse(JSON.stringify(this.state.oldPlan))})

  }

  saveToAdminLog=(adds,o,n)=>{
    cl(o)
    cl(n)
    let addObj={
      userId:globs.userData.session.userId,
      siteId:o.siteId,
      zoneId:o.zoneId,
      time:Math.floor(getTime())
    }
    if(o.zoneTier!=n.zoneTier){
      adds.push(
        Object.assign({},addObj,
        {
        action:"zoneTier",
        oldVal:o.zoneTier,
        newVal:n.zoneTier,
      }))
    }
  }

  saveZoneInfoToDb=async(data)=>{
    await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update", 
      sessionId: globs.userData.session.sessionId, body: data})
  }

  saveZoneInfo=(doSave)=>{// do Save, means save to db, not means copy current settings
    if(!doSave){
      this.zoneTiers=[]
    }
    let gzi=globs.zonesInfo.info
    let adminAdds=[]
    for(let i=0;i<gzi.length;i++){
      if(doSave){
        let zoneNew = {zoneId: gzi[i].zoneId}
        let zoneOld = {}
        let zoneChange = false
        if (this.zoneTiers[i]!=gzi[i].zoneTier){
          cl(`update ${gzi[i].zoneTier}`)
          zoneNew.zoneTier = gzi[i].zoneTier
          zoneOld.zoneTier = this.zoneTiers[i]
          zoneChange = true
        }
        if (zoneChange) {
          cl(zoneNew)
          this.saveZoneInfoToDb(zoneNew)
          this.saveToAdminLog(adminAdds, zoneOld, gzi[i])
        }
      }else{
        this.zoneTiers.push(gzi[i].zoneTier)
      }
//       if(gzi[i].siteId==siteId){
//         return gzi[i].zoneId
//       }
    }
//     cl(adminAdds)
    if(doSave){addToAdminLog(adminAdds)}
//     cl(this.zoneNames)
  }

  showPlanSummary = (state, neededLicenses) => {
    let total = 0
    let annual = (state.add_ons.annual) ? 12 : 1
    let annualDiscount = (state.add_ons.annual) ? .9 : 1
    let unlockedPrice = allZones["zone"]["unlocked"]
    let neededPrice = round((unlockedPrice - getZoneDiscount(state)) * annual * annualDiscount * neededLicenses)
//     cl([neededLicenses, neededPrice])
    return (
      <>
      <div className="clearfloat"></div><br/>
      <table>
      <tbody>
      <tr>
        <td>Add {neededLicenses} {state.add_ons.annual ? "Annual" : "Monthly"} licenses</td>
        <td>:</td>
        <td>{neededPrice}</td>
      </tr>
      {Object.entries(allZones).map(([type, price])=>{
          // let qtys = state.add_ons.zone_qty[type]
          // return Object.entries(qtys).map(([tier, val]) => {
          //   if (tier == zoneTypes["unlocked"]) {
          //     let t_total = val * (price[tier] - getZoneDiscount(state)) * annual * annualDiscount
          //     total += t_total
              // if (val > 0) {
              //   return (
              //     <tr key={type + tier}>
              //       <td>{val}</td>
              //       <td>{capitalize(tier)}</td>
              //       <td>{capitalize(type)} (${price[tier]})</td>
              //       <td>{round(t_total * annual)}</td>
              //     </tr>
              //   )
              // }
          //   }
          // })
        })
      }
      {Object.entries(allAddons).map(([type, price])=> {
        if (type == "API Access" && state.add_ons.api_access) {
          total += price * annual
          // return (
          //   <tr>
          //     <td>1</td>
          //     <td></td>
          //     <td>{capitalize(type)} (${price})</td>
          //     <td>{round(price * annual)}</td>
          //   </tr>
          // )
        }
      })
      }
      </tbody>
      </table>
      <><div className="clearfloat"></div><br/></>
      </>      
    )
  }

  showPrice = (total, state) => {
    let ret = []
    let new_total = total
    let all_discounts = 0
    let total_count_arr = Object.entries(allZones).map(([type, price]) => getZonesCount(type, state, true)) 
    let total_count = total_count_arr.reduce((val1, val2) => val1 + val2)
    // calculate zone discount -> can be by zone_type or tier
    let zone_discount = getZoneDiscount(state)
    let annual = state.add_ons.annual == "Annual" ? 12 : 1
    let key=0
    if (zone_discount > 0 && total_count > 0) {
      // ret.push(
      //   <tr key="zone-discount">
      //     <td>Zone Discount</td>
      //     <td>{total_count}</td>
      //     <td>-{round(zone_discount * annual)} per controller</td>
      //     <td>-{round(zone_discount * total_count * annual)}</td>
      //   </tr>
      // )
      // all_discounts = zone_discount * total_count * annual
      // new_total = new_total - (zone_discount * total_count * annual)
    }
    if (annual == 12  && total_count > 0) {
      // ret.push(
      //   <tr key={key++}>
      //     <td>Annual Discount</td>
      //     <td></td>
      //     <td>10%</td>
      //     <td>-{round(new_total * .10)}</td>
      //   </tr>
      // )
      all_discounts += new_total * .1
      new_total = new_total * .9
    }
    if (ret != "" && total_count > 0) {
      // ret.push(
      //   <tr key={key++}>
      //     <td>Total Discount</td>
      //     <td></td>
      //     <td></td>
      //     <td>-{round(all_discounts)}</td>
      //   </tr>
      // )
    }
    // ret.push(
    //   <tr key={key++}>
    //     <td>Estimated Total</td>
    //     <td></td>
    //     <td></td>
    //     <td>{round(new_total)}</td>
    //   </tr>
    // )
    return ret
  }

  showCurrentPlan = () => {
    // show details of old plan
    // all accounts should be initialized to "inactive trial" - zones should still be counted but inactive trial discount will show
    // being on trial means that all zone and feature limits are bypassed
    if (this.state.oldPlan) {
      return (
        <div>
          <h2>Current Plan</h2>
          {this.showPlanSummary(this.state.oldPlan)}
        </div>
      )
    }
  }

  // // show license confirm
  // showEnableConfirm = () => {
  //   // let diff=this.props.parms.opacity-this.state.opacity
  //   // if(diff){setTimeout(this.doFading,1)}
  //   if (this.state.currPlan) {
  //     return(
  //       <>
  //         <div className="popup" style={{
  //           opacity: this.state.opacity,
  //           display:(this.state.displayEnableConfirm)?"block":"none"}}>
  //           <p>Confirm</p>
  //           <br />
  //           <div className="alignright">
  //           {this.showCurrentPlan()}
  //           <br/>
  //           <h2>New Plan</h2>
  //           {this.showPlanSummary(this.state.currPlan)}
  //           {this.showDifference()}
  //           <br/>
  //           {this.showUnlockZonesSelect()}
  //           <C18Button00 className="icon" onClick={e=>this.onCancel()}>
  //             Cancel
  //           </C18Button00>
  //           <C18Button00 className="icon" onClick={e=>this.onConfirm()}>
  //             Confirm
  //           </C18Button00>
  //           <div className="clearfloat"></div><br/>
  //           </div>
  //         </div>
  //         <div className="popup-scrim"></div>
  //       </>
  //     )
  //   }    
  // }

  // pop up comparison screen
  showCheckout = () => {
//     if(config.host=="mjdemo"){return}
//     cl("skipping showCheckout")
//     return
//     cl("show check")
    // let diff=this.props.parms.opacity-this.state.opacity
    // if(diff){setTimeout(this.doFading,1)}
    if (this.state.currPlan) {
      // if need to buy licenses, show regular style
      // else show unlock licenses style
      let oldLicenses = this.state.oldPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]] 
      let oldUnlockCount = globs.zonesInfo.info.filter((z) => z.zoneTier == zoneTypes["unlocked"]).length
      let newUnlockCount = this.state.newConfig.filter((z) => z.zoneTier == zoneTypes["unlocked"]).length + 
        this.state.unlockedAllZones.filter((z) => z.zoneTier == zoneTypes["unlocked"]).length
      let existingLicenses = oldLicenses - oldUnlockCount
      let neededLicenses = (existingLicenses - newUnlockCount) < 0 ? Math.abs(existingLicenses - newUnlockCount) : 0
      let needLicense = neededLicenses > 0 
      let confirmTxt = (needLicense) ? "Place your order" : "Apply license(s)"
      let licenseTxt
      let applyTxt 
      if (needLicense) {
        licenseTxt = <span>Adding <u>{neededLicenses}</u> license(s) to your plan </span>
        if (existingLicenses > 0) applyTxt = <span>after applying <u>{existingLicenses}</u> license(s)</span>
      } else {
        licenseTxt = <span>You have <u>{existingLicenses}</u> license(s) available</span>
      }
      let titleTxt = (this.state.unlockAllZones) ? <h3><u>{newUnlockCount}</u> zone(s) do not have a paid subscription</h3> :
        <h3><u>{this.state.zoneName}</u> does not have a paid subscription</h3>
//       cl([oldLicenses, oldUnlockCount, newUnlockCount, existingLicenses, (existingLicenses - newUnlockCount), needLicense])
      return(
        <>
          <div className="popup" style={{
            opacity: this.state.opacity,
            display:(this.state.displayCheckout)?"block":"none",
            textAlign:"center"
          }}>
            {titleTxt}
            <br />
            {(!this.state.owner && !this.state.admin) ?
              <h3>Please contact the owner or an admin of this account to manage licenses.</h3>
            : 
              <h3>{licenseTxt}{applyTxt}</h3>
            }
            <div className="clearfloat"></div><br/>
            <div className="alignright">
            {needLicense && this.showPlanSummary(this.state.currPlan, neededLicenses)}
            {needLicense && this.showDifference()}
            <C18Button00 className="outlined" onClick={e=>this.onCancel()}>
              Cancel
            </C18Button00>
            <div className="divider"/>
            <C18Button00 disabled={!this.state.owner && !this.state.admin} className="outlined" onClick={e=>this.onConfirm(needLicense)}>
              {confirmTxt}
            </C18Button00>
            <div className="clearfloat"></div><br/>
            {this.showUnlockZonesSelect()}
            <div className="clearfloat"></div><br/>
            {!this.state.owner && !this.state.admin && 
              <C18Button00 className="outlined" onClick={e=>history.push("/usa/c18/admin/manageSubscription")}>
                Manage Subscription
              </C18Button00>
            }
            <div className="clearfloat"></div><br/>
            </div>
          </div>
          <div className="popup-scrim"></div>
        </>
      )
    }
  }

  showDifference = () => {
    if (this.state.displayCheckout && this.state.preview) {
      cl(this.state.preview.invoice_collection)
      if (!this.state.preview.invoice_collection.charge_invoice) {
        let oldTotal = getTotal(this.state.oldPlan)
        let newTotal = getTotal(this.state.currPlan)
        let diff = newTotal - oldTotal
        return ((diff > 0) ?
          <div>Today, you will be charged {round(diff)}. </div>
            :
          <div>Today, you will be credited {round(diff)}. </div>
        )
      } else {
        let chargeTotal = this.state.preview.invoice_collection.charge_invoice.total
        let creditTotal = this.state.preview.invoice_collection.credit_invoices[0]?.total || 0
        let chargeBalance = this.state.preview.invoice_collection.charge_invoice.balance
        let creditBalance = this.state.preview.invoice_collection.credit_invoices[0]?.balance || 0
        let planTotal = this.state.plan.currencies[0]?.unitAmount || 0
        let msg = []
        cl([chargeTotal, creditTotal, chargeBalance, creditBalance])
        if (planTotal > 0) {
          // msg += `The total prorated charge is ${round(chargeTotal)}. The ${this.state.currPlan.custom_fields.Period} charge upon renewal will be ${round(planTotal)}. `
          msg.push(<div><em>**The new {this.state.currPlan.add_ons.annual ? "Annual" : "Monthly"} charge upon renewal will be {round(planTotal)}.</em></div>)
        }
        // if (creditTotal < 0) {
        //   // msg += `The total prorated credit from your previous subscription is ${round(Math.abs(creditTotal))}. `
        //   msg.push(<div>The total prorated credit from your previous subscription is {round(Math.abs(creditTotal))}. </div>)
        // }
        // if (creditBalance < 0) {
        //   // msg += `Your account will be credited ${round(creditBalance)} today.`
        //   msg.push(<div><em>You will be credited {round(Math.abs(creditBalance))} today.</em></div>)
        // }
        if (chargeBalance > 0) {
          // msg += `You will be charged ${round(chargeBalance)} today.`
          msg.push(<div><em>You will be charged a prorated amount of {round(chargeBalance)} today.</em></div>)
        } else {
          msg.push(<div><em>You will not be charged today.</em></div>)
        }
        return (
        <div>
          {msg}
          <div className="clearfloat"></div><br/>
        </div>)
      }
    }
  }

  showUnlockZonesSelect = () => {
    return (
      <div className="slide-switch">
        <label className="switch">
          <C18Input00
            type="checkbox"
            id="unlock-all-zones-toggle"
            name="unlock-all-zones-toggle"
            checked={this.state.unlockAllZones}
            onChange={e=>this.unlockAllZones(!this.state.unlockAllZones)}
          />
          <span className="slider round"></span>
        </label>
        <label className="switch-label" htmlFor="unlock-all-zones-toggle">Unlock all remaining zones on {this.siteName}</label>
        <><div className="clearfloat"></div><br/></>
      </div>
    )
  }

  /* END SUBSCRIPTION METHODS */
  
  render(){
//     cl("render zones")
    // cl(this.props, globs)
    
//       cl("render")
//     cl(globs.zonesInfo.info[0].inNet)
  if (this.state.loaded) {
    return (
        <div>
          <h1>{this.siteName} Zones</h1>
          <div id="content-area">
            <section className={"link4 "+(this.state.tileMode)?"no-section":""}>
              <C18DateFormat00 parms={{
                leftString: dateToDisplayDate(new Date(),"mm/dd/yyyy h:mm ap", calcTimezoneOffset(this.timezone)),
                leftClass: "date",
                type:"listTile",
                parent:"zones",
                value: this.state.tileMode,
                valueId: "tileMode",
                timeZone: this.timezone,
                weather: true,
                siteId: this.props.parms.site,
                apiKey: this.apiKey,
                onChange: o=>this.onChange("format",o)
              }}/>
              {this.showZones()}
            </section>
          </div>
          {this.showCheckout()}
        </div>
    )
    }else{
      return <div id="content-area">loading zone list. . .</div>
    }
  }
}
      
export default C18ZonesListTiles00;
